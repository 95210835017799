import i18n from "../i18n";

export const addUserInfo = (info) => {
  return {
    type: "ADD_USER_INFO",
    payload: info
  };
};

export const updateFavoriteItems = (list) => {
  return {
    type: "UPDATE_FAVORITES",
    payload: list
  };
};
export const logoutUser = () => {
  localStorage.removeItem("SHOP_USER");
  localStorage.removeItem("woo-session");
  return {
    type: "LOG_OUT_USER"
  };
};

export const setLanguage = (language) => {
  localStorage.setItem("language", language);
  i18n.changeLanguage(language);

  return {
    type: "SET_LANGUAGE",
    payload: language
  };
};
