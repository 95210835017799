import React from "react";

import { useDispatch } from "react-redux";

import { addIdToCart } from "../../../actions/cartActions";

import useCartMutations from "../../../useCartMutation";

const AddToCart = (props) => {
  const {
    productAttributes: { id = 0, quantity = 1 }
  } = props;
  const dispatch = useDispatch();

  const { addToCart } = useCartMutations(id, null);

  const handleCartUpdate = (e) => {
    e.preventDefault();

    addToCart(id, quantity, null, null, {});
    dispatch(addIdToCart(id));

    props.onClick(e);
  };

  return (
    <button
      onClick={(e) => handleCartUpdate(e)}
      className={props.buttonClasses}
    >
      {props.buttonText}
    </button>
  );
};

export default AddToCart;
