import React from "react";

import "./ListedProducts.scss";

import { ListedProductsList } from "./ListedProductsList/ListedProductsList";
import { ListedProductsTitle } from "./ListedProductsTitle/ListedProductsTitle";

export const ListedProducts = (props) => {
  return (
    <div className="ListedProducts mt-4">
      <ListedProductsTitle title={props.title} />
      <ListedProductsList products={props.products} />
    </div>
  );
};
