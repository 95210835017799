import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";

import "../SectionSlider/SectionSlider.scss";
import "@splidejs/splide/dist/css/splide.min.css";

function Slider({ offers }) {
  return (
    <Splide
      options={{
        type: "slide",
        rewind: true,
        autoplay: true,
        perMove: 1,
        perPage: 1,
        gap: "2rem",
        arrows: offers.length > 1,
        pagination: false
      }}
    >
      {offers.map((offer, index) => {
        // If no offer image, don't render the slide
        if (!offer.offerImage) {
          return null;
        }

        return (
          <SplideSlide key={index}>
            {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href={offer?.offerLink} className="SectionSlider__item">
              <img
                src={offer.offerImage.mediaItemUrl}
                alt={`Sarajeva offer ${index}`}
              />
            </a>
          </SplideSlide>
        );
      })}
    </Splide>
  );
}
export default Slider;
