import { gql } from "apollo-boost";

export const GET_CART = gql`
  query getCart {
    cart {
      contents(first: 100) {
        nodes {
          key
          product {
            id
            productId
            name
            description
            type
            onSale
            slug
            averageRating
            reviewCount
            image {
              id
              sourceUrl
              altText
            }
            galleryImages {
              nodes {
                id
                sourceUrl
                altText
              }
            }
            productAttributes {
              englishProductName
            }
            ... on SimpleProduct {
              id
              name
              price
              regularPrice
              stockStatus
              salePrice
              defaultAttributes {
                nodes {
                  id
                  attributeId
                  name
                  value
                }
              }
            }
          }
          variation {
            id
            variationId
            name
            description
            type
            onSale
            price
            regularPrice
            stockStatus
            salePrice
            image {
              id
              sourceUrl
              altText
            }
            attributes {
              nodes {
                id
                name
                value
              }
            }
          }
          quantity
          total
          subtotal
          subtotalTax
        }
      }
      appliedCoupons {
        nodes {
          couponId
          discountType
          amount
          dateExpiry
          products {
            nodes {
              id
            }
          }
          productCategories {
            nodes {
              id
            }
          }
        }
      }
      subtotal
      subtotalTax
      shippingTax
      shippingTotal
      total
      totalTax
      feeTax
      feeTotal
      discountTax
      discountTotal
    }
  }
`;

export const ADD_TO_CART = gql`
  mutation ($input: AddToCartInput!) {
    addToCart(input: $input) {
      cartItem {
        key
        product {
          id
          productId
          name
          description
          type
          onSale
          slug
          averageRating
          reviewCount
          image {
            id
            sourceUrl
            altText
          }
          galleryImages {
            nodes {
              id
              sourceUrl
              altText
            }
          }
          ... on SimpleProduct {
            id
            name
            price
            regularPrice
            stockStatus
            salePrice
            defaultAttributes {
              nodes {
                id
                attributeId
                name
                value
              }
            }
          }
        }
        variation {
          id
          variationId
          name
          description
          type
          onSale
          price
          regularPrice
          stockStatus
          salePrice
          image {
            id
            sourceUrl
            altText
          }
          attributes {
            nodes {
              id
              attributeId
              name
              value
            }
          }
        }
        quantity
        total
        subtotal
        subtotalTax
      }
    }
  }
`;

export const UPDATE_ITEM_QUANTITIES = gql`
  mutation ($input: UpdateItemQuantitiesInput!) {
    updateItemQuantities(input: $input) {
      items {
        key
        product {
          id
          productId
          name
          description
          type
          onSale
          slug
          averageRating
          reviewCount
          image {
            id
            sourceUrl
            altText
          }
          galleryImages {
            nodes {
              id
              sourceUrl
              altText
            }
          }
          ... on SimpleProduct {
            id
            name
            price
            regularPrice
            stockStatus
            salePrice
            defaultAttributes {
              nodes {
                id
                attributeId
                name
                value
              }
            }
          }
        }
        variation {
          id
          variationId
          name
          description
          type
          onSale
          price
          regularPrice
          stockStatus
          salePrice
          image {
            id
            sourceUrl
            altText
          }
          attributes {
            nodes {
              id
              attributeId
              name
              value
            }
          }
        }
        quantity
        total
        subtotal
        subtotalTax
      }
      removed {
        key
        product {
          id
          productId
        }
        variation {
          id
          variationId
        }
      }
      updated {
        key
        product {
          id
          productId
        }
        variation {
          id
          variationId
        }
      }
    }
  }
`;

export const CHECK_CART = gql`
  query checkCart($productId: Int!, $variationId: Int) {
    isInCart(productId: $productId, variationId: $variationId) @client
    getCartItem(productId: $productId, variationId: $variationId) @client
  }
`;
