import React from "react";

import { NavLink } from "react-router-dom";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { addIdToCart } from "../../../actions/cartActions";

import "./ProductSectionButton.scss";

import useCartMutations from "../../../useCartMutation";

const ProductSectionButton = (props) => {
  const {
    productAttributes: { id = 0, quantity = 1 }
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { addToCart } = useCartMutations(id, null);

  const handleCartUpdate = (e) => {
    e.preventDefault();
    addToCart(id, quantity, null, null, {});
    dispatch(addIdToCart(id));
    props.updateStatus();
  };

  return (
    <div className="d-flex flex-column align-items-end">
      <div className="ProductSectionButton__extras">
        <NavLink to={`${props.slug}`}>{t("title.add_specifications")}</NavLink>
      </div>

      <button
        onClick={(e) => handleCartUpdate(e)}
        className={
          "ProductSectionButton ProductSectionButton--" + props.buttonClass
        }
      >
        {props.title}
      </button>
    </div>
  );
};

export default ProductSectionButton;
