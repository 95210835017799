import gql from "graphql-tag";

export const HOME_PRODUCTS_QUERY = gql`
  query productsQuery {
    products(first: 40, where: { status: "PUBLISHED", categoryIdNotIn: 1080 }) {
      nodes {
        id
        productId
        ... on SimpleProduct {
          id
          image {
            id
            sourceUrl(size: LARGE)
          }
          name
          slug
          onSale
          salePrice
          regularPrice
          stockStatus
        }
        productAttributes {
          topProdukt
          englishProductName
        }
      }
    }
  }
`;

export const CART_RELATED_PRODUCTS_QUERY = gql`
  query relatedProductsQuery {
    products(
      first: 8
      where: { status: "PUBLISHED", categoryIn: ["embelsirat", "pije"] }
    ) {
      nodes {
        id
        productId
        ... on SimpleProduct {
          id
          image {
            id
            sourceUrl(size: LARGE)
          }
          name
          slug
          onSale
          salePrice
          regularPrice
          stockStatus
          productAttributes {
            englishProductName
          }
        }
      }
    }
  }
`;
