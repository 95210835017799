import React from "react";

import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import "./GoToCheckoutButton.scss";

const GoToCheckoutButton = (props) => {
  const { t } = useTranslation();

  if (!props.canProcess) {
    return (
      <span className="GoToCheckoutButton GoToCheckoutButton--disabled">
        {t("title.proceed_with_payment")}
      </span>
    );
  }

  return (
    <NavLink to="/proceso-porosine" className="GoToCheckoutButton">
      {t("title.proceed_with_payment")}
    </NavLink>
  );
};

export default GoToCheckoutButton;
