const uiStateReducer = (
  state = {
    searchValue: "",
    isNavToggled: false
  },
  action
) => {
  switch (action.type) {
    case "INIT_UI_STATES":
      return { ...action.payload };
    case "TOGGLE_NAV":
      let isNavToggled = state.isNavToggled;
      return { ...state, isNavToggled: !isNavToggled };
    case "CLOSE_NAV":
      return { ...state, isNavToggled: false };
    case "FILL_SEARCH_VALUE":
      return { ...state, searchValue: action.payload };
    default:
      return state;
  }
};

export default uiStateReducer;
