import React from "react";

import ContentLoader from "react-content-loader";

const ProductTitleLoader = () => {
  return (
    <div className="ProductTitleLoader">
      <ContentLoader
        height={20}
        width={800}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="1" rx="0" ry="0" width="130" height="20" />
      </ContentLoader>
    </div>
  );
};

export default ProductTitleLoader;
