import React from "react";

import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { formatDate } from "../../../assets/Helpers";

import "./Order.scss";

const Order = (props) => {
  const { t } = useTranslation();

  return (
    <div className="Order">
      <div className="Order__header">
        {props.orderId && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">{t("title.order")}:</h4>
            <h4 className="Order__info_value">{props.orderId}</h4>
          </div>
        )}
        {props.status && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">{t("title.status")}:</h4>
            <h4 className="Order__info_value">{props.status}</h4>
          </div>
        )}
        {props.date && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">{t("title.order_date")}:</h4>
            <h4 className="Order__info_value">{formatDate(props.date)}</h4>
          </div>
        )}
        {props.dateCompleted && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">{t("title.shipping_date")}:</h4>
            <h4 className="Order__info_value">
              {formatDate(props.dateCompleted)}
            </h4>
          </div>
        )}
        {props.total && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">{t("title.total")}</h4>
            <h4 className="Order__info_value">
              {props.total.replace("&nbsp;", "")}
            </h4>
          </div>
        )}
        <div className="Order__header__item Order__header__item--link">
          <NavLink to={"/porosite/" + props.id} className="Order__info_link">
            {t("description.see_more")}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Order;
