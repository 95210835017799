import React, { useState } from "react";

import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Query } from "react-apollo";

import gql from "graphql-tag";

import { Container } from "reactstrap";

import { LANGUAGES } from "../../constants";

import { getSubNavigationRoute } from "../../assets/Helpers";

import "./MainNavigation.scss";

import NavigationLoader from "./NavigationLoader";

const MainNavigation = (props) => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.user);
  const [opened, setOpened] = useState("");

  const handleClick = (key) => {
    if (opened === key) {
      setOpened("");
      return;
    }
    setOpened(key);
  };

  return (
    <Query
      query={gql`
        {
          menus(where: { slug: "main" }) {
            nodes {
              menuItems(first: 20) {
                nodes {
                  id
                  label
                  url
                  menuEnglish {
                    englishLabel
                  }
                }
              }
            }
          }
        }
      `}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <nav className="MainNavigation">
              <Container>
                <NavigationLoader />
              </Container>
            </nav>
          );
        }

        if (error) {
          return <p>{t("title.something_went_wrong")}...</p>;
        }

        const menuItems = data.menus.nodes[0].menuItems.nodes;

        return (
          <nav className="MainNavigation">
            {menuItems.length > 0 && (
              <Container>
                <ul className="MainNavigation__list d-flex">
                  {menuItems.map((item, key) => {
                    const centerClass = key !== 0 ? "text-center" : "";
                    const link = getSubNavigationRoute(item.url);

                    return (
                      <li
                        key={`${key}-menu`}
                        onClick={() => handleClick(key)}
                        className={`flex-fill ${centerClass} ${
                          key === opened ? "is-opened" : ""
                        }`}
                      >
                        <NavLink
                          onClick={props.onClick}
                          to={"/produktet/" + link[1]}
                        >
                          {(language === LANGUAGES.en
                            ? item.menuEnglish.englishLabel
                            : item.label) || item.label}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </Container>
            )}
          </nav>
        );
      }}
    </Query>
  );
};

export default MainNavigation;
