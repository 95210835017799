import React from "react";

import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import Logo from "../../../components/TopHeader/Logo/Logo";

import "./FooterMain.scss";

const FooterMain = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user);

  return (
    <div className="FooterMain">
      <div className="FooterMain__inner d-flex">
        <div className="FooterMain__half w-50">
          <div className="FooterMain__logo">
            <Logo white />
          </div>
        </div>
        <div className="FooterMain__half w-50">
          <ul className="FooterMain__list">
            <li>
              <button>{t("title.my_account")}</button>
              <ul className="FooterMain__sub_list">
                {userData.isLogged && (
                  <li>
                    <NavLink to="/llogaria">{t("title.my_profile")}</NavLink>
                  </li>
                )}
                <li>
                  <NavLink to="/shporta">{t("title.cart")}</NavLink>
                </li>
              </ul>
            </li>
            <li className="FooterMain__list__contact_info">
              <button>{t("title.contact")}</button>
              <p>
                <span>Rr. Rexhep Luci - Qendër - 046 701 700</span>
                <span>Rruga B - 045 832 467</span>
                <span>Albi Mall - 045 301 980</span>
                <span>Galeria (Prizren) - 048 80 4003</span>
                <span>Marigona Residence - 045 384 642</span>
                <span>Park Plazza (Podujevë) - 048 804 001</span>
                <span>Central Park (Fushë Kosovë) - 048 804 007</span>
                <span>Prishtina Mall - 048 804 009</span>
                <span>Albi Mall (Gjilan) - 048 665 540</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterMain;
