import React from "react";

import { NavLink, withRouter } from "react-router-dom";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { emptyCart } from "../../../actions/cartActions";
import { logoutUser } from "../../../actions/userActions";

import "./UserSidebar.scss";

const UserSidebar = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleLogOut = (e) => {
    e.preventDefault();

    dispatch(emptyCart());
    dispatch(logoutUser());
    props.history.replace("/");
  };

  return (
    <div className="UserSidebar">
      <ul className="UserSidebar__list">
        <li>
          <NavLink to="/llogaria">{t("title.my_account")}</NavLink>
        </li>
        <li>
          <NavLink to="/adresa">{t("title.address")}</NavLink>
        </li>
        <li>
          <NavLink to="/porosite">{t("title.orders")}</NavLink>
        </li>
        <li>
          <button
            onClick={(e) => handleLogOut(e)}
            className="UserSidebar__logout"
          >
            {t("title.logout")}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default withRouter(UserSidebar);
