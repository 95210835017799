import React from "react";

import "./CartItems.scss";

import CartItem from "./CartItem/CartItem";

const CartItems = (props) => {
  const products = props.items;
  return (
    <div className="CartItems">
      {products.map((item, key) => {
        return <CartItem key={"cart-item-" + key} {...item} />;
      })}
    </div>
  );
};

export default CartItems;
