import React from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-apollo";

import gql from "graphql-tag";

import ContentLoader from "react-content-loader";

import { Container } from "reactstrap";

import GoogleMaps from "../../components/GoogleMaps/GoogleMaps";
import ThemeSection from "../../components/ThemeSection/ThemeSection";

import "./ContentPage.scss";

const PAGE_QUERY = gql`
  query PageQuery($slug: String) {
    pages(where: { nameIn: [$slug] }, first: 1) {
      nodes {
        id
        title
        pageId
        content
      }
    }
  }
`;

const ContentPage = (props) => {
  const { t } = useTranslation();
  const pageSlug = props.match.params.slug;

  const {
    data: pageData,
    loading,
    error
  } = useQuery(PAGE_QUERY, {
    variables: {
      slug: pageSlug
    }
  });

  if (loading) {
    return (
      <div className="ContentPage">
        <Container>
          <ThemeSection>
            <ContentLoader
              height={500}
              width={1480}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="20" y="20" rx="0" ry="0" width="445" height="44" />
              <rect x="20" y="100" rx="0" ry="0" width="1400" height="20" />
              <rect x="20" y="140" rx="0" ry="0" width="1400" height="20" />
              <rect x="20" y="180" rx="0" ry="0" width="1400" height="20" />
              <rect x="20" y="220" rx="0" ry="0" width="1400" height="20" />
              <rect x="20" y="260" rx="0" ry="0" width="1400" height="20" />
              <rect x="20" y="300" rx="0" ry="0" width="1400" height="20" />
              <rect x="20" y="340" rx="0" ry="0" width="1400" height="20" />
              <rect x="20" y="380" rx="0" ry="0" width="1400" height="20" />
              <rect x="20" y="420" rx="0" ry="0" width="1000" height="20" />
            </ContentLoader>
          </ThemeSection>
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className="ContentPage">
        <p>{t("title.please_try_again")}..</p>
      </div>
    );
  }

  function createMarkup() {
    return { __html: page.content };
  }

  const page = pageData.pages.nodes[0];

  return (
    <div className="ContentPage">
      <Container>
        <ThemeSection>
          <h1>{page.title}</h1>
          <div
            className="ContentPage__content"
            dangerouslySetInnerHTML={createMarkup()}
          ></div>
          {(page.pageId === 13814 || page.pageId === 5470) && <GoogleMaps />}
        </ThemeSection>
      </Container>
    </div>
  );
};

export default ContentPage;
