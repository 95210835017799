import React from "react";

import { useTranslation } from "react-i18next";

import FormModal from "../../components/FormModal/FormModal";
import FormTitle from "../../components/FormModal/FormTitle/FormTitle";

import LoginForm from "./LoginForm/LoginForm";

const Login = () => {
  const { t } = useTranslation();

  return (
    <FormModal halfModal={true}>
      <FormTitle title={t("title.login")} />
      <LoginForm />
    </FormModal>
  );
};

export default Login;
