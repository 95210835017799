import gql from "graphql-tag";

export const GET_HOME_SLIDER = gql`
  query getHomeSlider {
    themeOptions {
      themeOffers {
        offersList {
          offerLink
          offerImage {
            mediaItemUrl
          }
        }
      }
    }
  }
`;
