import React from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Col, Row } from "reactstrap";

import { getProductSpecialRequest } from "../../../../helpers/productSpecialRequest";

import { LANGUAGES } from "../../../../constants";

const CardCheckoutItem = (props) => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.user);
  const { product, quantity, total } = props;
  const productSpecialRequest = getProductSpecialRequest(product.productId);

  return (
    <div className="CardCheckout__table__item">
      <Row>
        <Col xs="6" sm="6">
          <div className="CardCheckout__product">
            <div className="CardCheckout__product__image">
              {product.image && (
                <img src={product.image.sourceUrl} alt={product.name} />
              )}
            </div>
            <div className="CardCheckout__product__content">
              <div>
                <h4>
                  {language === LANGUAGES.en &&
                  product.productAttributes?.englishProductName
                    ? product.productAttributes.englishProductName
                    : product.name}
                </h4>
                {/* <p>Kodi: MMSU-1</p> */}
              </div>
            </div>
          </div>
        </Col>
        <Col xs="6">
          <Row>
            <Col xs="4" sm="4">
              <h6 className="text-center">{quantity}</h6>
            </Col>
            <Col xs="4" sm="4">
              <h6 className="text-center">
                {product.price.replace("&nbsp;", "")}
              </h6>
            </Col>
            <Col xs="4" sm="4">
              <h6 className="text-center">{total.replace("&nbsp;", "")}</h6>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs="12">
              {productSpecialRequest.specialRequest !== "" && (
                <div>
                  <p className="CartItem__info m-0">
                    <b>{t("title.specifications")}:</b>
                  </p>
                  <p className="m-0"> {productSpecialRequest.specialRequest}</p>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CardCheckoutItem;
