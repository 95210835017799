import React, { useEffect, useReducer } from "react";

import classnames from "classnames";

import "./QuantityInput.scss";

function reducer(state, action) {
  switch (action.type) {
    case "increment":
      return { count: state.count + 1, isInitial: false };
    case "decrement":
      return { count: state.count - 1, isInitial: false };
    default:
      throw new Error();
  }
}

const QuantityInput = (props) => {
  const initial = { count: props.quantity || 1, isInitial: true };
  const [state, dispatch] = useReducer(reducer, initial);

  const classes = classnames({
    QuantityInput: true,
    "QuantityInput--small": props.smallBox,
    "QuantityInput--inline": props.inlineBox
  });

  useEffect(() => {
    if (!state.isInitial) {
      props.onChange(state.count);
    }
  }, [state.count, state.isInitial]);

  return (
    <div className={classes}>
      <span
        className="QuantityInput__counter QuantityInput__counter--decrement"
        onClick={(e) => {
          if (state.count !== 1) {
            dispatch({ type: "decrement" });
          }
        }}
      ></span>
      <input
        type="number"
        min="1"
        value={state.count}
        onChange={(e) => {}}
        style={{ pointerEvents: "none" }} // Disable manual input
      />
      <span
        className="QuantityInput__counter"
        onClick={(e) => {
          dispatch({ type: "increment" });
        }}
      ></span>
    </div>
  );
};

export default QuantityInput;
