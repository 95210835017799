import React from "react";

import { NavLink, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import classnames from "classnames";

import "./MobileFixedButton.scss";

export const MobileFixedButton = () => {
  const { t } = useTranslation();
  const route = useLocation();

  const classes = classnames("MobileFixedButton", {
    "MobileFixedButton--hidden":
      route.pathname === "/shporta" || route.pathname === "/proceso-porosine"
  });

  return (
    <div className={classes}>
      <NavLink to="/shporta">{t("title.proceed_to_cart")}</NavLink>
    </div>
  );
};
