import React from "react";

import "./LoadMoreButton.scss";

const LoadMoreButton = (props) => {
  return (
    <div className="LoadMoreButton">
      <button onClick={() => props.onClick()}>{props.title}</button>
    </div>
  );
};

export default LoadMoreButton;
