import React from "react";

import { useTranslation } from "react-i18next";

import "./FooterBottom.scss";

export const FooterBottom = () => {
  const { t } = useTranslation();

  const date = new Date(),
    currentYear = date.getFullYear();

  return (
    <div className="FooterBottom border-top py-4 text-center">
      <p className="mb-0">
        {t("description.developed_by")}:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="kutia"
          href="https://kutia.net/"
          title="Certified E-commerce Experts"
        >
          Kutia.net
        </a>{" "}
        - © {currentYear}. {t("description.all_rights_reserved")}
      </p>
    </div>
  );
};
