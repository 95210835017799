import React from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-apollo";

import { GET_HOME_SLIDER } from "../../../queries/sliderQueries";

import Slider from "../../../components/Slider/Slider";

import "./HomeSlider.scss";

import HomeSliderLoader from "./HomeSliderLoader";

export const HomeSlider = () => {
  const { t } = useTranslation();
  const { loading, data, error } = useQuery(GET_HOME_SLIDER);

  if (loading) {
    return (
      <div className="HomeSlider HomeSlider--loading">
        <HomeSliderLoader />
      </div>
    );
  }

  if (error) {
    return <p>{t("title.please_try_again")}</p>;
  }

  const sliderData = data.themeOptions.themeOffers.offersList;

  return (
    <div className="HomeSlider">
      {sliderData && <Slider offers={sliderData} />}
      {/* {sliderData && <SectionSlider data={sliderData} />} */}
    </div>
  );
};
