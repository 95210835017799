import React from "react";

import { withRouter } from "react-router-dom";

import "./ForgotPassword.scss";

import ResetPasswordAccount from "./ResetPasswordAccount/ResetPasswordAccount";
import ResetPasswordEmail from "./ResetPasswordEmail/ResetPasswordEmail";

const queryString = require("query-string");

const ForgotPassword = (props) => {
  const params = queryString.parse(props.location.search);

  if (params.key) {
    return <ResetPasswordAccount resetKey={params.key} login={params.login} />;
  }

  return <ResetPasswordEmail />;
};

export default withRouter(ForgotPassword);
