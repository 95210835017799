import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import classnames from "classnames";

import { addProductTexts } from "../../assets/Helpers";
import cartIcon from "../../assets/images/cart-black.svg";

import "./CartButtons.scss";

import AddToCart from "./AddToCart/AddToCart";

const CartButtons = (props) => {
  const [inCart, setInCart] = useState(false);
  const [buttonText, setButtonText] = useState(addProductTexts.default);

  const { t } = useTranslation();

  const buttonClasses = classnames({
    CartButtons__button: true,
    "CartButtons__button--buy": buttonText !== addProductTexts.update,
    "CartButtons__button--buy_update": buttonText === addProductTexts.update
  });

  useEffect(() => {
    if (inCart) {
      setButtonText(addProductTexts.update);
      return;
    }
  }, [props.quantity]);

  const handleCartClick = (e) => {
    setInCart(true);
    setButtonText(addProductTexts.success);
  };

  return (
    <div className="CartButtons d-flex align-items-end flex-fill">
      <AddToCart
        onClick={(e) => handleCartClick(e)}
        buttonText={t(buttonText)}
        buttonClasses={buttonClasses}
        productAttributes={{
          id: props.id,
          quantity: props.quantity
        }}
      />
      <NavLink to="/shporta" className="CartButtons__cart">
        <img src={cartIcon} alt="Shiko shportën" />
      </NavLink>
    </div>
  );
};

export default CartButtons;
