const defaultValue = {
  id: "guest",
  isLogged: false,
  favorites: [],
  language: localStorage.getItem("language") || "sq"
};

const userReducer = (state = defaultValue, action) => {
  switch (action.type) {
    case "ADD_USER_INFO":
      return { ...action.payload };
    case "UPDATE_FAVORITES":
      return { ...state, favorites: [action.payload] };
    case "LOG_OUT_USER":
      return defaultValue;
    case "SET_LANGUAGE":
      return { ...state, language: action.payload };
    default:
      return state;
  }
};

export default userReducer;
