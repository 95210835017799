import React from "react";

import { useTranslation } from "react-i18next";

import { graphql } from "react-apollo";

import gql from "graphql-tag";

import { HomeProducts } from "../../containers/Home/HomeProducts/HomeProducts";

import "./ProductSingle.scss";

import ProductMainLoader from "./ProductSingleMain/ProductMainLoader";
import ProductMobileLoader from "./ProductSingleMain/ProductMobileLoader";
import ProductSingleMain from "./ProductSingleMain/ProductSingleMain";

const ProductSingle = (props) => {
  const data = props.data,
    loading = data.loading;
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className="ProductSingle">
        <div className="ProductMainLoader">
          {window.innerWidth < 768 ? (
            <ProductMobileLoader />
          ) : (
            <ProductMainLoader />
          )}
        </div>
      </div>
    );
  }

  const product = data.products.nodes[0];

  return (
    <div className="ProductSingle d-flex flex-column">
      <ProductSingleMain product={product} />
      <HomeProducts title={t("title.selected_just_for_you")} />
    </div>
  );
};

const GetPostInformation = gql`
  query PostQuery($slug: String) {
    products(where: { slug: $slug }) {
      nodes {
        description
        ... on SimpleProduct {
          image {
            id
            sourceUrl(size: LARGE)
          }
          id
          productId
          name
          productAttributes {
            englishProductName
          }
          slug
          onSale
          salePrice
          regularPrice
          stockStatus
        }
      }
    }
  }
`;

export default graphql(GetPostInformation, {
  options: (props) => {
    const slug = props.slug;
    const limit = window.innerWidth > 1366 && window.innerWidth < 1600 ? 7 : 5;
    return {
      variables: {
        slug,
        sidebarLimit: limit
      }
    };
  }
})(ProductSingle);
