import React from "react";

import ContentLoader from "react-content-loader";

const ProductLoader = () => (
  <div className="ProductLoader">
    <ContentLoader
      height={550}
      width={400}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="60" rx="0" ry="0" width="400" height="350" />
      <rect x="0" y="440" rx="0" ry="0" width="150" height="40" />
      <rect x="250" y="440" rx="0" ry="0" width="150" height="40" />
      <rect x="0" y="500" rx="0" ry="0" width="500" height="40" />
    </ContentLoader>
  </div>
);

export default ProductLoader;
