import React from "react";

import FormModal from "../../components/FormModal/FormModal";

import "../../styles/general/_form.scss";
import "./Register.scss";

import RegisterForm from "./RegisterForm/RegisterForm";

const Register = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <FormModal>
      <RegisterForm onSubmit={(e) => handleSubmit(e)} />
    </FormModal>
  );
};

export default Register;
