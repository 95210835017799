import React from "react";

import { Col, Row } from "reactstrap";

import ProductLoader from "../ProductItem/ProductLoader";

const ProductsLoader = (props) => {
  const items = [];

  for (let i = 0; i < props.total; i++) {
    items.push(i);
  }

  const _renderProductLoaders = (array) =>
    array.map((item) => {
      return (
        <Col md="3" xs="12" key={item}>
          <ProductLoader />
        </Col>
      );
    });

  return (
    <div className="ProductsLoader">
      <Row>{_renderProductLoaders(items)}</Row>
    </div>
  );
};

export default ProductsLoader;
