import React from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-apollo";

import { HOME_PRODUCTS_QUERY } from "../../../queries/productQueries";

import { ListedProducts } from "../../../components/ListedProducts/ListedProducts";
import ProductsLoader from "../../../components/ProductSection/ProductsContainer/ProductsLoader";
import ProductTitleLoader from "../../../components/ProductSection/ProductSectionTitle/ProductTitleLoader";
import ThemeSection from "../../../components/ThemeSection/ThemeSection";

import "./HomeProducts.scss";

export const HomeProducts = (props) => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(HOME_PRODUCTS_QUERY);

  if (loading) {
    return (
      <>
        <ThemeSection>
          <ProductTitleLoader />
          <ProductsLoader total={20} />
        </ThemeSection>
      </>
    );
  }

  if (error) {
    return (
      <>
        <ThemeSection>
          <p>{t("title.please_try_again")}</p>
        </ThemeSection>
      </>
    );
  }

  const selectedProductsOrder = () => {
    if (!data.products.nodes) {
      return [];
    }

    const firstProducts = data.products.nodes.filter((item) => {
      return item.productAttributes.topProdukt !== null;
    });

    const otherProducts = data.products.nodes.filter((item) => {
      return item.productAttributes.topProdukt === null;
    });

    return [...firstProducts, ...otherProducts];
  };

  const items = selectedProductsOrder();

  return (
    <div className="HomeProducts mt-2">
      <ListedProducts title={props.title} products={items} />
    </div>
  );
};
