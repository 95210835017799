import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "react-apollo";

import gql from "graphql-tag";

import v4 from "uuid/v4";

import { Col, Form, Row } from "reactstrap";

import BillingDetails from "../../components/CheckoutForms/BillingDetails/BillingDetails";
import UserSettings from "../../components/UserSettings/UserSettings";
import UserSettingsTitle from "../../components/UserSettings/UserSettingsTitle/UserSettingsTitle";

import { localUser } from "../../assets/Helpers";

import "../../styles/general/_form.scss";

const UPDATE_ADDRESS_MUTATION = gql`
  mutation updateCustomerMutation($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      clientMutationId
    }
  }
`;
const USER_ADDRESS_DATA = gql`
  query addressQuery($id: ID) {
    customer(id: $id) {
      billing {
        firstName
        lastName
        address1
        address2
        city
        phone
        country
        email
        state
        postcode
      }
      shipping {
        firstName
        lastName
        address1
        address2
        city
        phone
        email
        state
        postcode
      }
    }
  }
`;

const Address = () => {
  const { t } = useTranslation();
  const isDisabled = false;
  const userInfo = localUser();

  const [addressData, setAddressData] = useState({
    billing: {
      firstName: "",
      lastName: "",
      address: "",
      apartment: "",
      telephone: "",
      email: "",
      isLoaded: false
    },
    shipping: {
      firstName: "",
      lastName: "",
      address: "",
      apartment: "",
      telephone: "",
      email: "",
      comment: "",
      differentShipping: true,
      isLoaded: false
    },
    isValid: false
  });

  const [address, { data: mutationData, loading: mutationLoading }] =
    useMutation(UPDATE_ADDRESS_MUTATION);

  const {
    loading: loadingBilling,
    error: errorBilling,
    data: dataBilling
  } = useQuery(USER_ADDRESS_DATA, {
    variables: {
      id: userInfo.ID
    }
  });

  if (!addressData.billing.isLoading && dataBilling) {
    const billingInfo = dataBilling.customer.billing;
    if (billingInfo) {
      setAddressData({
        ...addressData,
        billing: {
          ...addressData.billing,
          firstName: billingInfo.firstName,
          lastName: billingInfo.lastName,
          company: billingInfo.company,
          city: billingInfo.city,
          country: billingInfo.country,
          address: billingInfo.address1,
          apartment: billingInfo.address2,
          telephone: billingInfo.phone,
          email: billingInfo.email,
          isLoading: true
        }
      });
    }
  }

  if (!addressData.shipping.isLoading && dataBilling) {
    const shippingInfo = dataBilling.customer.shipping;
    if (shippingInfo) {
      setAddressData({
        ...addressData,
        shipping: {
          ...addressData.shipping,
          firstName: shippingInfo.firstName,
          lastName: shippingInfo.lastName,
          address: shippingInfo.address1,
          apartment: shippingInfo.address2,
          telephone: shippingInfo.phone,
          email: shippingInfo.email,
          isLoading: true
        }
      });
    }
  }

  const handleBillingChange = (name, value) => {
    setAddressData({
      ...addressData,
      billing: {
        ...addressData.billing,
        [name]: value
      }
    });
  };

  const handleShippingChange = (name, value) => {
    setAddressData({
      ...addressData,
      shipping: {
        ...addressData.shipping,
        [name]: value
      }
    });
  };

  const handleCheckoutSubmit = (e) => {
    e.preventDefault();

    const { billing, shipping } = addressData;
    address({
      variables: {
        input: {
          clientMutationId: v4(),
          id: userInfo.ID,
          billing: {
            firstName: billing.firstName,
            lastName: billing.lastName,
            phone: billing.telephone,
            address1: billing.address,
            address2: billing.apartment,
            company: billing.company,
            state: "XK",
            country: "XK",
            city: "Prishtina",
            email: billing.email
          },
          shipping: {
            firstName: shipping.firstName,
            lastName: shipping.lastName,
            address1: shipping.address,
            address2: shipping.apartment,
            company: shipping.company,
            state: "XK",
            country: "XK",
            city: "Prishtina"
          }
        }
      }
    });
  };

  return (
    <div className="Profile">
      <UserSettings>
        <UserSettingsTitle title={t("title.update_address")} />
        <Form
          onSubmit={(e) => {
            handleCheckoutSubmit(e);
          }}
        >
          <Row>
            <Col xs="12" sm="12" lg="6">
              <BillingDetails
                details={addressData.billing}
                onChange={(name, value) => handleBillingChange(name, value)}
              />
            </Col>
            {/* <Col xs="12" sm="12" lg="6">
              <ShippingDetails
                details={addressData.shipping}
                shippingTitle={true}
                onChange={(name, value) => handleShippingChange(name, value)}
              />
            </Col> */}
          </Row>
          <div className="GeneralForm__input">
            <input
              value={
                !mutationLoading
                  ? t("title.update_address_details")
                  : t("title.updating_address")
              }
              type="submit"
              disabled={isDisabled || mutationLoading}
            />
          </div>
        </Form>
      </UserSettings>
    </div>
  );
};

export default Address;
