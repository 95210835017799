import React from "react";

import { NavLink } from "react-router-dom";

import logo from "../../../assets/images/SARAJEVA_logo-white.svg";
import whiteLogo from "../../../assets/images/SARAJEVA_logo-white.svg";

import "./Logo.scss";

const Logo = (props) => {
  return (
    <div className={"Logo" + (props.white ? " Logo--white" : "")}>
      <NavLink to="/">
        <img src={props.white ? whiteLogo : logo} alt="Sarajeva Steak House" />
      </NavLink>
    </div>
  );
};

export default Logo;
