import React, { useState } from "react";

import { withRouter } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useMutation } from "react-apollo";

import gql from "graphql-tag";

import { string } from "yup";

import v4 from "uuid/v4";

import { Alert, Form } from "reactstrap";

import FormModal from "../../../components/FormModal/FormModal";
import FormTitle from "../../../components/FormModal/FormTitle/FormTitle";
import GeneralFormInput from "../../../components/GeneralForm/GeneralFormInput";

import "../ForgotPassword.scss";

const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($inputData: ResetUserPasswordInput!) {
    resetUserPassword(input: $inputData) {
      clientMutationId
      user {
        firstName
      }
    }
  }
`;

const ResetPasswordAccount = (props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    password: ""
  });
  const [validPassword, setValidPassword] = useState(true);

  const [
    resetUserPassword,
    { data: resetData, loading: loadingReset, error: resetError }
  ] = useMutation(RESET_USER_PASSWORD);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const schema = string().matches(/^(?=.*\d)(?=.*[a-zA-Z]).{7,}$/);

  if (loadingReset) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">{t("title.please_wait")}</p>
      </FormModal>
    );
  }

  if (resetData) {
    setTimeout(() => {
      props.history.push("/");
    }, 5000);

    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">{t("description.password_updated")}</p>
      </FormModal>
    );
  }

  if (resetError) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">
          {resetError.message.replace("GraphQL error: ", "")}
        </p>
      </FormModal>
    );
  }

  const isDisabled = formData.password === "" || loadingReset;

  return (
    <FormModal halfModal={true}>
      <FormTitle title={t("title.new_password")} />
      <div className="GeneralForm">
        <Form
          onSubmit={async (e) => {
            e.preventDefault();

            try {
              const isvalid = await schema.isValid(formData.password);

              if (!isvalid) {
                setValidPassword(false);
                return;
              }

              resetUserPassword({
                variables: {
                  inputData: {
                    clientMutationId: v4(),
                    key: props.resetKey,
                    login: props.login,
                    password: formData.password
                  }
                }
              });
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {!validPassword && (
            <Alert color="warning">{t("validator.password")}</Alert>
          )}
          <GeneralFormInput
            name="password"
            type="password"
            placeholder={t("placeholder.new_password")}
            onChange={(e) => handleChange(e)}
            value={formData.password || ""}
          />
          <div
            className={
              "GeneralForm__input" +
              (isDisabled ? " GeneralForm__input--disabled" : "")
            }
          >
            <input
              type="submit"
              disabled={isDisabled}
              value={loadingReset ? t("title.updating") : t("title.update")}
            />
          </div>
        </Form>
      </div>
    </FormModal>
  );
};

export default withRouter(ResetPasswordAccount);
