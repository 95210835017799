import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useQuery } from "@apollo/react-hooks";

import gql from "graphql-tag";

import { addIdToCart, addItemsToCart } from "../../actions/cartActions";
import { addUserInfo } from "../../actions/userActions";
import { logoutUser } from "../../actions/userActions";

import { isTokenExpired, localUser } from "../../assets/Helpers";

import { GET_CART } from "../../useCartQueries";

const USER_INFO = gql`
  query userInfo($userID: ID!) {
    user(id: $userID) {
      id
      email
      userId
      firstName
      lastName
    }
  }
`;

const AppWrapper = (props) => {
  const localUserInfo = localUser(),
    authToken = localUserInfo.AUTHTOKEN;
  const dispatch = useDispatch();
  const { data: cartData } = useQuery(GET_CART);
  const [loadedCart, setCartStatus] = useState(false);

  const cartHasItems = cartData && cartData.cart.contents.nodes.length;

  if (cartHasItems && !loadedCart) {
    setCartStatus(true);

    const ids = cartData.cart.contents.nodes.map(
      (item) => item.product.productId
    );
    dispatch(addItemsToCart(ids));
  }

  const userInfo = useQuery(USER_INFO, {
    variables: {
      userID: localUserInfo.ID
    }
  });

  if (localUserInfo && isTokenExpired(authToken)) {
    localStorage.removeItem("SHOP_USER");
    localStorage.removeItem("woo-session");
    dispatch(logoutUser());
  }

  useEffect(() => {
    if (userInfo && userInfo.data) {
      dispatch(
        addUserInfo({
          isLogged: true,
          ...userInfo.data.user
        })
      );
    }
  });

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AppWrapper;
