import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-apollo";

import gql from "graphql-tag";

import ContentLoader from "react-content-loader";

import { Col, FormGroup, Input, Label, Row } from "reactstrap";

import "./Payment.scss";

const PAYMENT_METHODS = gql`
  query PaymentMethods {
    paymentGateways {
      nodes {
        id
        description
        title
      }
    }
  }
`;

const Payment = (props) => {
  const [payment, setPayment] = useState({
    selectedPaymentMethod: ""
  });

  const { t } = useTranslation();
  const { loading, data: methods } = useQuery(PAYMENT_METHODS);

  if (loading) {
    return (
      <div className="Payment--loading">
        <ContentLoader
          height={80}
          width={200}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <circle cx="10" cy="40" r="5" />
          <rect x="25" y="36" rx="5" ry="5" width="130" height="8" />
          <circle cx="10" cy="64" r="5" />
          <rect x="25" y="60" rx="5" ry="5" width="130" height="8" />
          <rect x="9" y="7" rx="0" ry="0" width="104" height="13" />
        </ContentLoader>
      </div>
    );
  }

  const handleChange = (itemId) => {
    setPayment({
      ...payment,
      selectedPaymentMethod: itemId
    });
    props.onChange(itemId);
  };

  const paymentMethods = methods.paymentGateways.nodes;

  return (
    <div className="Payment">
      <div className="CheckoutTitle">
        <h5>{t("title.payment_method")}</h5>
      </div>
      <Row>
        <Col xs="12" sm="12">
          {paymentMethods.map((item) => {
            return (
              <FormGroup key={item.id} check>
                <Label check>
                  <Input
                    type="radio"
                    id={item.id}
                    name="payment"
                    required
                    label={item.title}
                    onChange={(e) => {
                      handleChange(item.id);
                    }}
                  />
                  {item.title}
                </Label>
              </FormGroup>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default Payment;
