import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { useMutation } from "react-apollo";

import gql from "graphql-tag";

import ReCAPTCHA from "react-google-recaptcha";
import v4 from "uuid/v4";

import { Alert } from "reactstrap";

import "./ResetPasswordButton.scss";

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      clientMutationId
    }
  }
`;

const ResetPasswordButton = (props) => {
  const { t } = useTranslation();
  const [
    sendPasswordResetEmail,
    { data: resetData, loading: loadingReset, error: resetError }
  ] = useMutation(SEND_PASSWORD_RESET_EMAIL);
  const recaptchaRef = React.createRef();

  const [hasErrors, toggleErrors] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  if (!props.userEmail) {
    return (
      <div className="ResetPasswordButton">
        <div className={"GeneralForm__input"}>{t("title.loading")}</div>
      </div>
    );
  }

  if (loadingReset) {
    return <p className="FormModal__error">{t("title.please_wait")}</p>;
  }

  if (resetData) {
    return (
      <p className="FormModal__error">
        {t("description.password_reset_link_sent")}
      </p>
    );
  }

  if (resetError) {
    return <p className="FormModal__error">{t("title.please_try_again")}</p>;
  }

  const handlePasswordReset = (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      toggleErrors(true);
      return;
    } else {
      toggleErrors(false);
    }

    sendPasswordResetEmail({
      variables: {
        input: {
          clientMutationId: v4(),
          username: props.userEmail,
          captcha: recaptchaValue
        }
      }
    });
  };

  return (
    <div className="ResetPasswordButton">
      {hasErrors && !captchaToken && (
        <Alert color="warning">{t("description.captcha_required")}</Alert>
      )}
      <div className={"GeneralForm__input"}>
        <div className="GeneralForm__input GeneralForm__input--recaptcha">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lc05c4UAAAAAHOXA390-IgP62d7iSFXP2EgqHUV"
            onChange={(e) => {
              setCaptchaToken(e);
            }}
          />
        </div>
        <input
          value={t("description.send_reset_password_email")}
          type="submit"
          onClick={(e) => {
            handlePasswordReset(e);
          }}
        />
      </div>
    </div>
  );
};

export default ResetPasswordButton;
