import React from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-apollo";

import NavigationButton from "../../components/Buttons/NavigationButton/NavigationButton";
import FormModal from "../../components/FormModal/FormModal";
import FormTitle from "../../components/FormModal/FormTitle/FormTitle";

import "./Cart.scss";

import { GET_CART } from "../../useCartQueries";
import CartItems from "./CartItems/CartItems";
import { CartRelatedProducts } from "./CartRelatedProducts/CartRelatedProducts";
import CartTotals from "./CartTotals/CartTotals";

const Cart = () => {
  const { t } = useTranslation();
  const { data, loading, refetch } = useQuery(GET_CART);
  const stateCart = useSelector((state) => state.cart);
  React.useEffect(() => {
    refetch();
  });

  if (loading || data === undefined) {
    return <div className="Cart"></div>;
  }

  if (data && data.cart.contents.nodes.length === 0) {
    return (
      <div className="Cart">
        <FormModal centered>
          {/*<div className="Cart__icon">*/}
          {/*<img src={cartIcon} alt="Empty Cart" />*/}
          {/*</div>*/}
          <p className="Cart__desc">{t("description.empty_cart")}</p>
          <NavigationButton to="/" title={t("title.add_products")} />
        </FormModal>
      </div>
    );
  }

  if (data.cart.contents.nodes.length !== stateCart.length) {
    setTimeout(() => refetch(), 500);
  }

  const cartData = data.cart,
    cartItems = cartData.contents.nodes;

  return (
    <div className="Cart">
      <FormModal>
        <FormTitle title={t("title.cart")} />
        <CartItems items={cartItems} />
        <CartTotals total={cartData.subtotal} />
      </FormModal>

      <CartRelatedProducts />
    </div>
  );
};

export default Cart;
