import React from "react";

import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import "./TotalCheckout.scss";

const TotalCheckout = (props) => {
  const { shippingAttributes } = props,
    subTotal = props.subTotal.replace("&nbsp;€", ""),
    calculated = +subTotal + +shippingAttributes.cost;

  const { t } = useTranslation();

  return (
    <div className="TotalCheckout">
      <div className="TotalCheckout__box">
        <p>{t("title.your_order")}</p>
        <div className="TotalCheckout__box__info">
          <p>
            {t("title.total")}
            <span>{props.subTotal.replace("&nbsp;", "")}</span>
          </p>
          <p>
            {t("title.transport")}: <span>{t(shippingAttributes.text)}</span>
          </p>
        </div>
        <div className="TotalCheckout__box__total">
          <h4>{t("title.total")}</h4>
          <h3>{calculated.toFixed(2)}€</h3>
          <input type="submit" value={t("title.pay")} />
        </div>
      </div>
      <p className="TotalCheckout__TermsCondition">
        {t("title.accept")}{" "}
        <NavLink to="/informacione/kushtet-e-perdorimit">
          {t("title.terms_and_conditions")}
        </NavLink>
      </p>
    </div>
  );
};

export default TotalCheckout;
