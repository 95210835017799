import React from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Col, Row } from "reactstrap";

import { LANGUAGES } from "../../../../constants";

const CardCheckoutItemMobile = (props) => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.user);
  const { product, quantity, total } = props;

  return (
    <div className="CardCheckout__table__item">
      <Row>
        <Col xs="5" sm="3" className="CardCheckout__product">
          <div>
            <div className="CardCheckout__product__image">
              {product.image && (
                <img src={product.image.sourceUrl} alt={product.name} />
              )}
            </div>
          </div>
        </Col>
        <Col xs="7" sm="9">
          <h5>
            {language === LANGUAGES.en &&
            product.productAttributes?.englishProductName
              ? product.productAttributes.englishProductName
              : product.name}
          </h5>
          <h6>
            <b>{t("title.quantity")}: </b> {quantity}
          </h6>
          <h6>
            <b>{t("title.price")}: </b>
            {product.price.replace("&nbsp;", "")}
          </h6>
          <h6>
            <b>{t("title.total")}: </b>
            {total.replace("&nbsp;", "")}
          </h6>
        </Col>
      </Row>
    </div>
  );
};

export default CardCheckoutItemMobile;
