import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import { LANGUAGES } from "../../../constants";

import "./ProductSingleMain.scss";

import ProductAttributes from "../ProductAttributes/ProductAttributes";

const ProductSingleMain = (props) => {
  const { language } = useSelector((state) => state.user);

  const product = props.product,
    origin = product.productOrigin;

  const productName = useMemo(() => {
    if (
      language === LANGUAGES.en &&
      product.productAttributes.englishProductName
    ) {
      return product.productAttributes.englishProductName;
    } else {
      return product.name;
    }
  }, [language, product.name, product.productAttributes.englishProductName]);

  return (
    <div className="ProductSingleMain mb-12 row">
      <div className="col col-12 col-sm-12 col-md-6">
        <div className="ProductSingleMain__thumb">
          <img src={product.image.sourceUrl} alt={product.name} />
        </div>
      </div>
      <div className="col col-12 col-sm-12 col-md-6">
        <ProductAttributes
          id={product.productId}
          stockStatus={product.stockStatus}
          title={productName}
          description={product.description}
          other={{
            productBrand: product.productBrand,
            productBarcode: product.productBarcode
          }}
          origin={origin}
          pricing={{
            onSale: product.onSale,
            salePrice: product.salePrice,
            regularPrice: product.regularPrice
          }}
        />
      </div>
    </div>
  );
};

export default ProductSingleMain;
