import React, { Fragment } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-apollo";

import { Container } from "reactstrap";

import CheckoutForm from "../../components/CheckoutForms/CheckoutForm";
import CheckoutFormGuest from "../../components/CheckoutForms/CheckoutFormGuest";
import ThemeSection from "../../components/ThemeSection/ThemeSection";

import { MIN_ORDER_PRICE } from "../../constants";

import "./Checkout.scss";

import { GET_CART } from "../../useCartQueries";

const Checkout = (props) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.user);
  const { data: cartData, loading: loadingCart } = useQuery(GET_CART);

  const currentHour = new Date().getHours(),
    currentMinutes = new Date().getMinutes(),
    isWorkingHours =
      currentHour >= 9 &&
      (currentHour < 22 || (currentHour === 22 && currentMinutes <= 45));

  if (loadingCart) {
    return (
      <Fragment>
        <Container>
          <ThemeSection>
            <p className="Checkout__loading">{t("description.loading_cart")}</p>
          </ThemeSection>
        </Container>
      </Fragment>
    );
  }

  if (cartData) {
    const total = parseFloat(
      cartData.cart.total.replace("&nbsp;€", "").replace(",", "")
    );

    if (total < MIN_ORDER_PRICE || !isWorkingHours) {
      props.history.push("/shporta");
    }
  }

  return (
    <Fragment>
      <Container>
        <ThemeSection>
          <div className="Checkout">
            <div className="CheckoutTitle">
              <h2>{t("title.complete_order")}</h2>
            </div>
            {userInfo.isLogged && !userInfo.firstTimeRegistered ? (
              <CheckoutForm cartData={cartData} />
            ) : (
              <CheckoutFormGuest cartData={cartData} />
            )}
          </div>
        </ThemeSection>
      </Container>
    </Fragment>
  );
};

export default withRouter(Checkout);
