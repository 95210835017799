import React from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-apollo";

import { CART_RELATED_PRODUCTS_QUERY } from "../../../queries/productQueries";

import { Container } from "reactstrap";

import { ListedProducts } from "../../../components/ListedProducts/ListedProducts";
import ProductsLoader from "../../../components/ProductSection/ProductsContainer/ProductsLoader";
import ProductTitleLoader from "../../../components/ProductSection/ProductSectionTitle/ProductTitleLoader";
import ThemeSection from "../../../components/ThemeSection/ThemeSection";

import "./CartRelatedProducts.scss";

export const CartRelatedProducts = () => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(CART_RELATED_PRODUCTS_QUERY);

  if (loading) {
    return (
      <>
        <Container>
          <ThemeSection>
            <ProductTitleLoader />
            <ProductsLoader total={8} />
          </ThemeSection>
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Container>
          <ThemeSection>
            <p>{t("title.please_try_again")}</p>
          </ThemeSection>
        </Container>
      </>
    );
  }

  return (
    <div className="CartRelatedProducts">
      <Container>
        <ListedProducts
          title={t("title.order_drinks")}
          products={data.products.nodes}
        />
      </Container>
    </div>
  );
};
