import React from "react";

import { Container } from "reactstrap";

import "./Footer.scss";

import { FooterBottom } from "./FooterBottom/FooterBottom";
import FooterMain from "./FooterMain/FooterMain";
import { FooterSocial } from "./FooterSocial/FooterSocial";

const Footer = () => {
  return (
    <footer className="Footer">
      <Container>
        <FooterMain />
        <FooterSocial />
      </Container>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
