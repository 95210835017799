import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./resources/en/translations.json";
import sq from "./resources/sq/translations.json";

const resources = {
  en: {
    translation: en
  },
  sq: {
    translation: sq
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "sq",
    returnNull: false,
    ns: ["translation"],
    compatibilityJSON: "v3",
    interpolation: {
      escapeValue: false,
      nestingOptionsSeparator: "."
    }
  })
  .catch((e) => {
    throw e;
  });

export default i18n;
