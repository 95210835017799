import React from "react";

import { NavLink } from "react-router-dom";

import { useQuery } from "react-apollo";

import { GET_THEME_OFFERS_QUERY } from "../../../queries/themeQueries";

import { Col, Row } from "reactstrap";

import "./HomeOffers.scss";

export const HomeOffers = () => {
  const { loading, error, data } = useQuery(GET_THEME_OFFERS_QUERY);

  if (loading) {
    return <></>;
  }

  if (error) {
    return (
      <>
        <p>error</p>
      </>
    );
  }

  const showOffers = data.themeOptions.themeOffers.showOffers;

  if (showOffers === null) {
    return <></>;
  }

  const offers = data.themeOptions.themeOffers.offersList;

  return (
    <div className="HomeOffers mt-4">
      <Row>
        {offers.map((item, key) => {
          return (
            <Col key={`${item.offerLink}-offer-${key}`} sm="6" xs="12">
              <div className="HomeOffers__item">
                <NavLink to={item.offerLink}>
                  <img
                    src={item.offerImage.mediaItemUrl}
                    alt="Oferta Sarajeva"
                  />
                </NavLink>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
