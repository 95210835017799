export const getProductSpecialRequest = (productId) => {
  let productsSpecialRequests = localStorage.getItem("special_requests");
  if (productsSpecialRequests) {
    productsSpecialRequests = JSON.parse(productsSpecialRequests);
    const productSpecialRequest = productsSpecialRequests.find(
      (x) => x.id === productId
    );
    if (productSpecialRequest) {
      return productSpecialRequest;
    }
  }
  return {
    id: productId,
    title: "",
    specialRequest: ""
  };
};

export const getSpecialRequestsString = (productIds) => {
  let productsSpecialRequests = localStorage.getItem("special_requests");
  let specialRequestString = "";
  if (productsSpecialRequests) {
    productsSpecialRequests = JSON.parse(productsSpecialRequests);
    productsSpecialRequests.forEach((val) => {
      if (productIds.includes(val.id)) {
        specialRequestString += `${val.title}: ${val.specialRequest}\n`;
      }
    });
  }
  return specialRequestString;
};

export const setProductSpecialRequest = (productId, title, specialRequest) => {
  let productsSpecialRequests = localStorage.getItem("special_requests");
  if (productsSpecialRequests) {
    productsSpecialRequests = JSON.parse(productsSpecialRequests);
    const index = productsSpecialRequests.findIndex((x) => x.id === productId);
    if (index >= 0) {
      productsSpecialRequests[index].title = title;
      productsSpecialRequests[index].specialRequest = specialRequest;
    } else {
      productsSpecialRequests.push({
        id: productId,
        title: title,
        specialRequest: specialRequest
      });
    }
    localStorage.setItem(
      "special_requests",
      JSON.stringify(productsSpecialRequests)
    );
  } else {
    localStorage.setItem(
      "special_requests",
      JSON.stringify([
        {
          id: productId,
          title: title,
          specialRequest: specialRequest
        }
      ])
    );
  }
};

export const removeSpecialRequests = () => {
  localStorage.removeItem("special_requests");
};
