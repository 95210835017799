import React from "react";

import { FormGroup, Input } from "reactstrap";

const CheckoutFormInput = (props) => {
  return (
    <FormGroup className="GeneralForm__input">
      <label className="CheckoutInputLabel">
        {props.label}
        {props.required && props.label ? "*" : ""}
        <Input
          {...props}
          onChange={(e) => {
            props.onChange(e);
          }}
        />
      </label>
    </FormGroup>
  );
};

export default CheckoutFormInput;
