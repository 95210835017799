import React from "react";

import ContentLoader from "react-content-loader";

const NavigationLoader = () => (
  <ContentLoader
    height={30}
    width={900}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="70" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="140" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="210" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="280" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="350" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="420" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="490" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="560" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="630" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="700" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="770" y="8" rx="0" ry="0" width="60" height="15" />
    <rect x="840" y="8" rx="0" ry="0" width="60" height="15" />
  </ContentLoader>
);

export default NavigationLoader;
