import React from "react";

import facebookIcon from "../../../assets/images/facebook.svg";
import instagramIcon from "../../../assets/images/instagram.svg";

import "./FooterSocial.scss";

const socialMedias = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/sarajevasteak",
    icon: facebookIcon
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/sarajevasteakhouse",
    icon: instagramIcon
  }
];

export const FooterSocial = () => {
  return (
    <div className="FooterSocial">
      <ul className="FooterSocial__list">
        {socialMedias.map((socialMedia) => (
          <li key={socialMedia.name}>
            <a
              href={socialMedia.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={socialMedia.icon} alt={socialMedia.name} />
              <span>{socialMedia.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
