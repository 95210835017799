import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { closeNav } from "../../../actions/uiStateActions";

import { Button, Input, InputGroup } from "reactstrap";

import "./Search.scss";

const Search = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch(),
    searchValue = useSelector((state) => state.uiState.searchValue),
    [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (searchValue && searchValue !== "") {
      setSearchInput(searchValue);
    }
  }, [searchValue]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(closeNav());
    props.history.push("/kerko?s=" + searchInput);
  };

  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className="Search my-2">
      <form onSubmit={handleSubmit}>
        <InputGroup className="search_input">
          <Input
            value={searchInput}
            onChange={handleChange}
            className="rounded-pill"
            placeholder={t("placeholder.search")}
          />
          <Button className="rounded-pill">{t("title.submit")}</Button>
        </InputGroup>
      </form>
    </div>
  );
};

export default withRouter(Search);
