import React from "react";

import classNames from "classnames";

import { Container } from "reactstrap";

import "./MobileNavigation.scss";

import MainNavigation from "../MainNavigation/MainNavigation";
import Search from "../TopHeader/Search/Search";

const MobileNavigation = (props) => {
  const navClasses = classNames({
    MobileNavigation: true,
    "MobileNavigation--active": props.isOpen
  });

  return (
    <div className={navClasses}>
      <Container>
        <div className="MobileNavigation__header">
          {props.showSearchInput && <Search />}
          <MainNavigation
          // onClick={props.closeNav}
          />
        </div>
      </Container>
    </div>
  );
};

export default MobileNavigation;
