import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-apollo";

import { GET_HEADER_ALERT_QUERY } from "../../queries/themeQueries";

import { Container } from "reactstrap";

import MainNavigation from "../../components/MainNavigation/MainNavigation";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import Search from "../../components/TopHeader/Search/Search";
import TopHeader from "../../components/TopHeader/TopHeader";

import "./Header.scss";

const Header = () => {
  const { t } = useTranslation();

  const isMobile = window.innerWidth < 1241;
  const [isOpen, toggleNav] = useState(true);
  const [showSearchInput, setShowSearchInput] = useState(false);

  const locationWithBoldPhoneNumbers = t("title.location").replace(
    /(\d{3} \d{3} \d{3})/g,
    "<strong>$1</strong>"
  );

  return (
    <header className="Header">
      <div className="Header__search py-2">
        <Container>
          <div className="d-flex align-items-center justify-content-between">
            <div className="Header__text d-flex flex-column">
              <div className="Header__text__wrapper d-flex">
                <p className="rajoni">{t("description.shipping_to")}</p>

                {/* <p className="nr">
                  &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<b>045 832 467</b>
                </p>   */}
                <p
                  className="vendi"
                  dangerouslySetInnerHTML={{
                    __html: locationWithBoldPhoneNumbers
                  }}
                />
              </div>
              <AlertParagraph />
            </div>
            <Search />
          </div>
        </Container>
      </div>
      <TopHeader
        isOpen={isOpen}
        toggleNav={() => toggleNav(!isOpen)}
        setShowSearchInput={setShowSearchInput}
      />
      {!isMobile ? (
        <MainNavigation />
      ) : (
        <MobileNavigation
          isOpen={isOpen}
          closeNav={() => toggleNav(false)}
          showSearchInput={showSearchInput}
        />
      )}
    </header>
  );
};

export default Header;

export const AlertParagraph = () => {
  const { loading, data, error } = useQuery(GET_HEADER_ALERT_QUERY);

  if (loading || error) {
    return <></>;
  }

  const message = data.themeOptions.headerInformation.alertMessage;

  if (!message || message.length === 0) {
    return <></>;
  }

  return <p className="covid_alert mt-2">{message}</p>;
};
