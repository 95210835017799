import React from "react";

import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Col, Row } from "reactstrap";

import "./CardCheckout.scss";

import FormModal from "../../FormModal/FormModal";
import CardCheckoutItem from "./CardCheckoutItem/CardCheckoutItem";
import CardCheckoutItemMobile from "./CardCheckoutItemMobile/CardCheckoutItemMobile";

const CardCheckout = (props) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 1025;
  const { cartData, loading } = props;

  if (loading) {
    return <p>{t("title.loading")}</p>;
  }

  if (cartData && cartData.cart.contents.nodes.length === 0) {
    return (
      <div className="Cart">
        <FormModal centered>
          <p className="Cart__desc">{t("description.cart.empty")}</p>
        </FormModal>
      </div>
    );
  }

  const cartItems = cartData.cart.contents.nodes;

  return (
    <div className="CardCheckout">
      <div className="CheckoutTitle">
        <h5>{t("title.card_summary")}</h5>
        <NavLink to="/shporta">{t("title.update_cart")}</NavLink>
      </div>
      <div className="CardCheckout__table__cover">
        <div className="CardCheckout__table">
          {!isMobile && (
            <div className="CardCheckout__table__header">
              <Row>
                <Col xs="6" sm="6">
                  <h6>{t("title.product")}</h6>
                </Col>
                <Col xs="2" sm="2">
                  <h6 className="text-center">{t("title.quantity")}</h6>
                </Col>
                <Col xs="2" sm="2">
                  <h6 className="text-center">{t("title.price")}</h6>
                </Col>
                <Col xs="2" sm="2">
                  <h6 className="text-center">{t("title.total")}</h6>
                </Col>
              </Row>
            </div>
          )}
          <div className="CardCheckout__table__main">
            {cartItems.map((item, key) => {
              return isMobile ? (
                <CardCheckoutItemMobile
                  key={"checkout-card-" + key}
                  {...item}
                />
              ) : (
                <CardCheckoutItem key={"checkout-card-" + key} {...item} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCheckout;
