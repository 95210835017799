import React from "react";

import { NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { setLanguage } from "../../../actions/userActions";

import { LANGUAGES } from "../../../constants";

import accountLogo from "../../../assets/images/avatar-white.svg";
import cartLogo from "../../../assets/images/cart-white.svg";

import "./UserNavigation.scss";

const UserNavigation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.user);
  const userData = useSelector((state) => state.user);
  const cartItems = useSelector((state) => state.cart);

  return (
    <ul className="UserNavigation align-items-center">
      <li className="UserNavigation__cart">
        <NavLink className="d-flex" to="/shporta">
          <span className="UserNavigation__logo">
            <img src={cartLogo} alt="Shporta" />
          </span>
          {cartItems.length !== 0 && (
            <span className="UserNavigation__items_total">
              {cartItems.length}
            </span>
          )}
          <span className="UserNavigation__text">{t("title.cart")}</span>
        </NavLink>
      </li>
      <li className="d-flex align-items-center">
        {userData.isLogged ? (
          <>
            <NavLink className="d-flex login-link" to="/llogaria">
              <span className="UserNavigation__logo">
                <img src={accountLogo} alt="Llogaria" />
              </span>
              <span
                className="UserNavigation__text"
                style={{ paddingTop: "3px" }}
              >
                {userData.firstName}
              </span>
            </NavLink>
          </>
        ) : (
          <>
            <NavLink className="d-flex" to="/kyqu">
              <span className="UserNavigation__logo">
                <img src={accountLogo} alt="Llogaria" />
              </span>
              <span
                className="UserNavigation__text"
                style={{ paddingTop: "3px" }}
              >
                {t("title.login")}
              </span>
            </NavLink>
          </>
        )}
      </li>

      <div className="ml-2 mr-1">
        <button
          className={classNames("text-white font-weight-light", {
            "active-language": language === LANGUAGES.sq
          })}
          onClick={() => dispatch(setLanguage(LANGUAGES.sq))}
        >
          AL
        </button>
        <button
          className={classNames("text-white font-weight-light", {
            "active-language": language === LANGUAGES.en
          })}
          onClick={() => dispatch(setLanguage(LANGUAGES.en))}
        >
          EN
        </button>
      </div>
    </ul>
  );
};

export default UserNavigation;
