import React, { Fragment } from "react";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import "./ProductSectionTitle.scss";

const ProductSectionTitle = (props) => {
  const isArchive = props.isArchive,
    title = props.title.replace("&amp;", "&");
  const { t } = useTranslation();

  return (
    <div className="ProductSectionTitle d-flex align-items-center justify-content-between">
      <h3 className="ProductSectionTitle__text">
        {!isArchive ? (
          <Link to={"/produktet/" + props.slug}>{title}</Link>
        ) : (
          <Fragment>{title}</Fragment>
        )}
      </h3>
      {!isArchive && (
        <Link
          to={"/produktet/" + props.slug}
          className="ProductSectionTitle__more"
        >
          {t("title.more")}
        </Link>
      )}
    </div>
  );
};

export default ProductSectionTitle;
