import React from "react";

import { withRouter } from "react-router";

import { useTranslation } from "react-i18next";

import FormModal from "../../components/FormModal/FormModal";
import Logo from "../../components/TopHeader/Logo/Logo";

import successIcon from "../../assets/images/tick-icon.png";

import "./SucessPage.scss";

const SuccessPage = (props) => {
  const { t } = useTranslation();

  setTimeout(function (e) {
    props.history.push("/");
  }, 3000);

  return (
    <div className="SuccessPage">
      <FormModal>
        <div className="SuccessPage__content">
          <Logo />
          <div className="SuccessPage__success">
            <img src={successIcon} alt="Succes Icon" />
            <p>{t("description.order_success")}</p>
          </div>
        </div>
      </FormModal>
    </div>
  );
};

export default withRouter(SuccessPage);
