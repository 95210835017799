import React, { useState } from "react";

import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import classnames from "classnames";

import { LANGUAGES } from "../../../constants";

import { addProductTexts } from "../../../assets/Helpers";

import "./ProductItem.scss";

import ProductSectionButton from "../../Buttons/ProductSectionButton/ProductSectionButton";
import QuantityInput from "../../QuantityInput/QuantityInput";
import ProductItemThumb from "./ProductItemThumb";

const ProductItem = (props) => {
  const [cartQuantity, updateQuantity] = useState(1),
    [buttonClass, setButtonClass] = useState("default"),
    [buttonText, setButtonText] = useState(addProductTexts.default);

  const { t } = useTranslation();
  const { language } = useSelector((state) => state.user);

  const priceClasses = classnames({
    product_price: true,
    "product_price--sale": props.onSale
  });

  const handleButtonSuccess = () => {
    setButtonClass("success");
    setButtonText(addProductTexts.success);
  };

  const handleQuantityUpdate = (value) => {
    updateQuantity(value);

    if (buttonClass === "default") {
      return;
    }

    setButtonClass("update");
    setButtonText(addProductTexts.update);
  };

  return (
    <div className="ProductItem">
      <div className="ProductItem__content">
        <NavLink to={"/produkti/" + props.slug}>
          <div className="ProductItem__thumb">
            {props.image && (
              <ProductItemThumb alt={props.name} src={props.image.sourceUrl} />
            )}
          </div>
          <h3 className="ProductItem__title p-3">
            {(language === LANGUAGES.en &&
            props.productAttributes?.englishProductName
              ? props.productAttributes.englishProductName
              : props.name
            ).replace("&amp;", "&")}
            ,
            <span className={priceClasses}>
              {props.regularPrice && (
                <span
                  style={props.onSale ? { textDecoration: "line-through" } : {}}
                >
                  {props.regularPrice.replace("&nbsp;", "")}
                </span>
              )}
              {props.onSale && (
                <span className="sale_price">
                  {props.salePrice.replace("&nbsp;", "")}
                </span>
              )}
            </span>
          </h3>
        </NavLink>
      </div>
      {props.stockStatus !== "OUT_OF_STOCK" && (
        <div className="ProductItem__info_box d-flex ProductItem__cart_info">
          <div className="ProductItem__info_right ProductItem__quantity">
            <span>{t("title.quantity")}:</span>
            <QuantityInput
              quantity={cartQuantity}
              onChange={(value) => handleQuantityUpdate(value)}
              smallBox
            />
          </div>
          <div className="ProductItem__add_button">
            <ProductSectionButton
              slug={"/produkti/" + props.slug}
              productAttributes={{
                quantity: cartQuantity,
                id: props.productId
              }}
              updateStatus={() => {
                handleButtonSuccess();
              }}
              buttonClass={buttonClass}
              title={t(buttonText)}
            />
          </div>
          <div className="ProductItem__cart_button d-flex justify-content-end">
            <NavLink to="/shporta">{t("title.see_cart")}</NavLink>
          </div>
        </div>
      )}
      {props.stockStatus === "OUT_OF_STOCK" && (
        <div className={"OutOfStock-button"}>{t("title.out_of_stock")}</div>
      )}
    </div>
  );
};

export default ProductItem;
