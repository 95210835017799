import React from "react";

import ContentLoader from "react-content-loader";

const ProductMainLoader = () => (
  <ContentLoader
    speed={2}
    width={1200}
    height={600}
    viewBox="0 0 1200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="400" height="400" />
    <rect x="445" y="1" rx="0" ry="0" width="144" height="20" />
    <rect x="445" y="37" rx="0" ry="0" width="64" height="20" />
    <rect x="445" y="100" rx="0" ry="0" width="50" height="10" />
    <rect x="445" y="120" rx="0" ry="0" width="250" height="100" />
    <rect x="445" y="240" rx="0" ry="0" width="40" height="10" />
    <rect x="445" y="260" rx="25" ry="25" width="100" height="40" />
    <rect x="560" y="260" rx="25" ry="25" width="160" height="40" />
    <rect x="740" y="260" rx="100" ry="100" width="40" height="40" />
  </ContentLoader>
);

export default ProductMainLoader;
