import React from "react";

import { Container } from "reactstrap";

import ProductSingle from "../../components/ProductSingle/ProductSingle";

import { getPathSlug } from "../../assets/Helpers";

const Article = (props) => {
  return (
    <div className="Article">
      <Container>
        <ProductSingle slug={getPathSlug(props.location.pathname)} />
      </Container>
    </div>
  );
};

export default Article;
