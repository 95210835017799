import React, { useEffect, useState } from "react";

import { NavLink, withRouter } from "react-router-dom";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { useMutation } from "react-apollo";

import gql from "graphql-tag";

import { string } from "yup";

import ReCAPTCHA from "react-google-recaptcha";

import { addUserInfo } from "../../../actions/userActions";

import { Alert, Col, Form, Row } from "reactstrap";

import NavigationButton from "../../../components/Buttons/NavigationButton/NavigationButton";
import FormTitle from "../../../components/FormModal/FormTitle/FormTitle";
import CheckoutFormInput from "../../../components/GeneralForm/CheckoutFormInput";

import "../../../styles/general/_form.scss";

const REGISTER_MUTATION = gql`
  mutation register(
    $firstname: String
    $lastname: String
    $address: String
    $password: String
    $telephone: String
    $email: String!
    $captcha: String!
  ) {
    registerCustomer(
      input: {
        clientMutationId: "uniqueId"
        username: $email
        firstName: $firstname
        lastName: $lastname
        email: $email
        shippingSameAsBilling: true
        password: $password
        captcha: $captcha
        billing: {
          firstName: $firstname
          lastName: $lastname
          country: XK
          address1: $address
          company: ""
          city: "Prishtina"
          phone: $telephone
        }
      }
    ) {
      customer {
        id
        firstName
        customerId
      }
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation LoginUser(
    $username: String!
    $password: String!
    $captcha: String!
  ) {
    login(
      input: {
        clientMutationId: "uniqueId"
        username: $username
        password: $password
        captcha: $captcha
      }
    ) {
      authToken
      refreshToken
      user {
        id
        name
        firstName
        lastName
        jwtAuthToken
        userId
      }
    }
  }
`;

const RegisterForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const [register, { data, error: registerError }] =
    useMutation(REGISTER_MUTATION);

  const [hasErrors, toggleErrors] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const schema = string().matches(/^(?=.*\d)(?=.*[a-zA-Z]).{7,}$/);

  const [login, { data: dataLogin }] = useMutation(LOGIN_MUTATION);

  const [registerData, setRegisterData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    telephone: "",
    email: "",
    password: "",
    confirmPassword: "",
    displayErrorLabel: "",
    isRegistered: false
  });
  const [validPassword, setValidPassword] = useState(true);

  useEffect(() => {
    if (registerError && registerError.message) {
      recaptchaRef.current.props.grecaptcha.reset();
    }
  }, [registerError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasErrors || !validPassword) {
      recaptchaRef.current.props.grecaptcha.reset();
    }
  }, [hasErrors, validPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  if (dataLogin && registerData.isRegistered) {
    const userInfo = {
      ID: dataLogin.login.user.id,
      AUTHTOKEN: dataLogin.login.authToken,
      REFRESH_TOKEN: dataLogin.login.refreshToken,
      userId: dataLogin.login.user.userId
    };

    localStorage.setItem("SHOP_USER", JSON.stringify(userInfo));

    dispatch(
      addUserInfo({
        id: userInfo.ID,
        isLogged: true,
        firstName: registerData.firstname,
        userId: userInfo.userId
      })
    );
    props.history.replace("/");
  }

  if (data && !registerData.isRegistered) {
    try {
      login({
        variables: {
          username: registerData.email,
          password: registerData.password,
          captcha: captchaToken
        }
      });
    } catch (e) {
      console.log(e);
    }

    setRegisterData({
      ...registerData,
      isRegistered: true
    });

    return (
      <div className="GeneralForm">
        <div className="Register--success">
          <h1>{t("title.welcome_message")}</h1>
          <h4>{t("description.register_success")}</h4>
          <NavigationButton to="/" title={t("title.continue_shopping")} />
        </div>
      </div>
    );
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.target.className = "form-control";
    setRegisterData({
      ...registerData,
      [name]: value
    });
  };

  const handleOnBlur = (e) => {
    if (e.target.value + "" === "") {
      e.target.className = "form-control error";
    }
  };

  const handleSubmitForm = (e) => {
    setRegisterData({
      ...registerData,
      displayErrorLabel: ""
    });
    if (registerData.password !== registerData.confirmPassword) {
      setRegisterData({
        ...registerData,
        displayErrorLabel: "validator.password_does_not_match"
      });
      return;
    }

    try {
      const { firstname, lastname, address, password, telephone, email } =
        registerData;

      register({
        variables: {
          firstname,
          lastname,
          address,
          password,
          telephone,
          email,
          captcha: captchaToken
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="GeneralForm">
      {registerData.displayErrorLabel !== "" && (
        <p className="error text-center">{t(registerData.displayErrorLabel)}</p>
      )}
      {data ? (
        <p className="sucess">{t("description.register_success")}</p>
      ) : (
        <>
          <FormTitle title={t("title.register")} />
          {registerError && (
            <Alert color="warning">{t("validator.register_form_error")}</Alert>
          )}
          {hasErrors && !captchaToken && (
            <Alert color="warning">{t("description.captcha_required")}</Alert>
          )}
          <Form
            onSubmit={async (e) => {
              e.preventDefault();

              if (!recaptchaRef) {
                toggleErrors(true);
                return;
              }

              const recaptchaValue = recaptchaRef.current.getValue();

              if (!recaptchaValue) {
                toggleErrors(true);
                return;
              } else {
                toggleErrors(false);
              }

              setCaptchaToken(recaptchaValue);

              const isvalid = await schema.isValid(registerData.password);

              if (!isvalid) {
                setValidPassword(false);
                return;
              }

              handleSubmitForm(e);
            }}
          >
            <Row>
              <Col xs="12" sm="6">
                <CheckoutFormInput
                  required
                  name="firstname"
                  type="text"
                  placeholder={t("placeholder.first_name") + "*"}
                  value={registerData.firstname || ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => handleOnBlur(e)}
                />
              </Col>
              <Col xs="12" sm="6">
                <CheckoutFormInput
                  required
                  type="text"
                  name="lastname"
                  placeholder={t("placeholder.last_name") + "*"}
                  value={registerData.lastname || ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => handleOnBlur(e)}
                />
              </Col>
              <Col xs="12" sm="6">
                <CheckoutFormInput
                  type="text"
                  name="address"
                  required
                  placeholder={t("placeholder.address") + "*"}
                  value={registerData.address || ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => handleOnBlur(e)}
                />
              </Col>
              <Col xs="12" sm="6">
                <CheckoutFormInput
                  type="text"
                  name="telephone"
                  required
                  placeholder={t("placeholder.phone") + "*"}
                  value={registerData.telephone || ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => handleOnBlur(e)}
                />
              </Col>
              <Col xs="12" sm="6">
                <CheckoutFormInput
                  required
                  type="email"
                  name="email"
                  placeholder={t("placeholder.email") + "*"}
                  value={registerData.email || ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => handleOnBlur(e)}
                />
              </Col>
              {!validPassword && (
                <Col xs="12" sm="12">
                  <Alert color="warning">{t("validator.password")}</Alert>
                </Col>
              )}
              <Col xs="12" sm="6">
                <CheckoutFormInput
                  required
                  type="password"
                  name="password"
                  placeholder={t("placeholder.password") + "*"}
                  value={registerData.password || ""}
                  onChange={(e) => handleChange(e)}
                />
              </Col>
              <Col xs="12" sm="6"></Col>
              <Col xs="12" sm="6">
                <CheckoutFormInput
                  required
                  type="password"
                  name="confirmPassword"
                  placeholder={t("placeholder.confirm_password") + "*"}
                  value={registerData.confirmPassword || ""}
                  onChange={(e) => handleChange(e)}
                />
              </Col>
            </Row>
            <div className="GeneralForm__input GeneralForm__input--recaptcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lc05c4UAAAAAHOXA390-IgP62d7iSFXP2EgqHUV"
              />
            </div>
            <div className="GeneralForm__input text-center">
              <input value={t("title.register")} type="submit" />
            </div>
            <p
              className="GeneralForm__text text-center"
              style={{ marginTop: "10px" }}
            >
              <NavLink to="/kyqu">
                {t("description.already_have_account_log_in")}
              </NavLink>
            </p>
          </Form>
        </>
      )}
    </div>
  );
};

export default withRouter(RegisterForm);
