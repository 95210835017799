import React from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/react-hooks";

import gql from "graphql-tag";

import { Col, Container, Row } from "reactstrap";

import ThemeSection from "../../../components/ThemeSection/ThemeSection";
import UserSettings from "../../../components/UserSettings/UserSettings";
import UserSettingsTitle from "../../../components/UserSettings/UserSettingsTitle/UserSettingsTitle";

import { getPathSlug } from "../../../assets/Helpers";

import "./OrderSingle.scss";

const USER_ORDER = gql`
  query UserOrder($id: ID!) {
    order(id: $id) {
      id
      date
      orderId
      subtotal
      shippingTotal
      paymentMethodTitle
      total
      billing {
        address1
        address2
        city
        company
        country
        email
        firstName
        lastName
        phone
        postcode
      }
      shipping {
        address1
        address2
        city
        company
        country
        email
        firstName
        lastName
        phone
        postcode
      }
      customer {
        email
        lastName
        firstName
        id
      }
      lineItems {
        nodes {
          product {
            id
            name
            image {
              id
              mediaItemUrl
            }
            ... on SimpleProduct {
              id
              name
              price
            }
          }
          quantity
          total
        }
      }
    }
  }
`;

const OrderSingle = () => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(USER_ORDER, {
    variables: {
      id: getPathSlug(window.location.href)
    }
  });
  let content = "";

  if (error) {
    content = (
      <div className="OrderSingle">
        <div className="Order__header">
          <UserSettingsTitle
            title={t("description.searched_order_not_found")}
          />
        </div>
      </div>
    );
  }

  if (loading) {
    content = (
      <div className="OrderSingle">
        <div className="Order__header">
          <UserSettingsTitle title={t("title.loading")} />
        </div>
      </div>
    );
  }

  if (data && data.order === null) {
    content = (
      <div className="Order__header">
        <UserSettingsTitle title={t("description.searched_order_not_found")} />
      </div>
    );
  }

  if (data && data.order) {
    content = (
      <div className="OrderSingle">
        <div className="Order__header">
          <UserSettingsTitle
            title={`${t("title.order")} #${data.order.orderId}`}
          />
        </div>
        <div className="OrderSingle__content">
          {data.order.lineItems &&
            data.order.lineItems.nodes.map((item, index) => (
              <div className="CartItem" key={item.product.name}>
                <div className="CartItem__thumb">
                  {item.product.image && (
                    <img
                      src={item.product.image.mediaItemUrl}
                      alt={item.product.name}
                    />
                  )}
                </div>
                <div className="CartItem__content">
                  {item.product.name && (
                    <h3 className="CartItem__info CartItem__info--title">
                      <b>{t("title.product")}:</b> {item.product.name}
                    </h3>
                  )}
                  {item.total && (
                    <h3 className="CartItem__info">
                      <b>{t("title.price")}:</b>
                      {item.product.price.replace("&nbsp;", "")}
                    </h3>
                  )}
                  {item.quantity && (
                    <h4 className="CartItem__info">
                      <b>{t("title.quantity")}:</b> {item.quantity}
                    </h4>
                  )}
                  {item.total && (
                    <h4 className="CartItem__total">
                      <b>{t("title.total_price")}:</b>
                      {item.total.replace("&nbsp;", "")}€
                    </h4>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="OrderSingle__footer">
          <div className="CartItem">
            <div className="CartItem__content">
              {data.order.subtotal && (
                <h3 className="CartItem__info CartItem__info--title">
                  <b>Subtotal:</b> {data.order.subtotal.replace("&nbsp;", "")}
                </h3>
              )}
              {data.order.shippingTotal && (
                <h3 className="CartItem__info">
                  <b>{t("title.transport")}:</b>
                  {data.order.shippingTotal.replace("&nbsp;", "")}
                </h3>
              )}
              {data.order.paymentMethodTitle && (
                <h4 className="CartItem__info">
                  <b>{t("title.payment_method")}:</b>{" "}
                  {data.order.paymentMethodTitle}
                </h4>
              )}
              {data.order.total && (
                <h4 className="CartItem__total">
                  <b>{t("title.total")}:</b>{" "}
                  {data.order.total.replace("&nbsp;", "")}
                </h4>
              )}
            </div>
          </div>
        </div>
        <Row>
          <Col md="6">
            <ThemeSection>
              <h3 className="CartItem__info CartItem__info--title">
                <b>Billing</b>
              </h3>
              {data.order.billing.firstName && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.name")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.billing.firstName}
                  </h4>
                </div>
              )}
              {data.order.billing.lastName && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.surname")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.billing.lastName}
                  </h4>
                </div>
              )}
              {data.order.billing.address1 && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.address")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.billing.address1}
                  </h4>
                </div>
              )}
              {data.order.billing.country && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.country")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.billing.country}
                  </h4>
                </div>
              )}
              {data.order.billing.postcode && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">
                    {t("title.postal_code")}:
                  </h4>
                  <h4 className="Order__info_value">
                    {data.order.billing.postcode}
                  </h4>
                </div>
              )}
              {data.order.billing.phone && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.phone")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.billing.phone}
                  </h4>
                </div>
              )}
              {data.order.billing.email && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">Email:</h4>
                  <h4 className="Order__info_value">
                    {data.order.billing.email}
                  </h4>
                </div>
              )}
            </ThemeSection>
          </Col>
          <Col md="6">
            <ThemeSection>
              <h3 className="CartItem__info CartItem__info--title">
                <b>Shipping</b>
              </h3>
              {data.order.shipping.firstName && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.name")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.shipping.firstName}
                  </h4>
                </div>
              )}
              {data.order.shipping.lastName && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.surname")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.shipping.lastName}
                  </h4>
                </div>
              )}
              {data.order.shipping.address1 && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.address")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.shipping.address1}
                  </h4>
                </div>
              )}
              {data.order.shipping.country && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.country")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.shipping.country}
                  </h4>
                </div>
              )}
              {data.order.shipping.postcode && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">
                    {t("title.postal_code")}:
                  </h4>
                  <h4 className="Order__info_value">
                    {data.order.shipping.postcode}
                  </h4>
                </div>
              )}
              {data.order.shipping.phone && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">{t("title.phone")}:</h4>
                  <h4 className="Order__info_value">
                    {data.order.shipping.phone}
                  </h4>
                </div>
              )}
              {data.order.shipping.email && (
                <div className="Order__header__item">
                  <h4 className="Order__info_title">Email:</h4>
                  <h4 className="Order__info_value">
                    {data.order.shipping.email}
                  </h4>
                </div>
              )}
            </ThemeSection>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Container>
      <UserSettings>{content}</UserSettings>
    </Container>
  );
};

export default OrderSingle;
