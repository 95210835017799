import React from "react";

import { Container } from "reactstrap";

import ProductSection from "../../components/ProductSection/ProductSection";

import { getPathSlug } from "../../assets/Helpers";

import "./Archive.scss";

const Archive = (props) => {
  const productSlug = props.location.pathname;
  return (
    <div className="Archive">
      <Container>
        <ProductSection limit="24" isArchive slug={getPathSlug(productSlug)} />
      </Container>
    </div>
  );
};

export default Archive;
