import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import classnames from "classnames";

import { Input } from "reactstrap";

import QuanitityInput from "../../../components/QuantityInput/QuantityInput";

import {
  getProductSpecialRequest,
  setProductSpecialRequest
} from "../../../helpers/productSpecialRequest";

import "./ProductAttributes.scss";

import CartButtons from "../../CartButtons/CartButtons";

const ProductAttributes = (props) => {
  const [cartQuantity, updateQuantity] = useState(1);
  const { other } = props,
    pricing = props.pricing;
  const { t } = useTranslation();

  const priceClasses = classnames({
    product_price: true,
    "product_price--sale": pricing.onSale
  });

  // Handle special request
  const productSpecialRequest = getProductSpecialRequest(props.id);
  productSpecialRequest.title = props.title;
  const [specialRequest, setSpecialRequest] = useState(
    productSpecialRequest.specialRequest
  );

  const onSpecialRequestChange = (e) => {
    setSpecialRequest(e.target.value);
    setProductSpecialRequest(props.id, props.title, e.target.value);
  };

  return (
    <div className="ProductAttributes">
      <div className="ProductAttributes__section d-flex">
        <div className="ProductAttributes__half">
          <h1 className="ProductAttributes__title">{props.title}</h1>
          <h3 className="ProductAttributes__price">
            <div className={priceClasses}>
              {pricing.regularPrice && (
                <h4>{pricing.regularPrice.replace("&nbsp;", "")}</h4>
              )}
              {pricing.onSale ? (
                <h4 className="sale_price">
                  {pricing.salePrice.replace("&nbsp;", "")}
                </h4>
              ) : (
                ""
              )}
            </div>
          </h3>
        </div>
        {other.productBarcode && (
          <div className="ProductAttributes__half d-flex justify-content-end">
            <div className="ProductAttributes__barcode">
              <p>
                <b>{t("title.barcode")}:</b>
                <br />
                {other.productBarcode}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="ProductAttributes__section">
        <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        <ul className="ProductAttributes__list">
          <li>
            <p>
              <span>{t("title.specifications")}:</span>
              <Input
                type="textarea"
                name="text"
                id="productData"
                value={specialRequest}
                onChange={onSpecialRequestChange}
              />
            </p>
          </li>
        </ul>
        {props.stockStatus !== "OUT_OF_STOCK" && (
          <div className="ProductAttributes__buttons_wrapper d-flex">
            <h3 className="ProductAttributes__quantity">
              <span className="text">{t("title.quantity")}:</span>
              <QuanitityInput
                quantity={cartQuantity}
                onChange={(value) => updateQuantity(value)}
                smallBox
              />
            </h3>
            <CartButtons id={props.id} quantity={cartQuantity} />
          </div>
        )}
        {props.stockStatus === "OUT_OF_STOCK" && (
          <div className={"OutOfStock-button"}>{t("title.out_of_stock")}</div>
        )}
      </div>
    </div>
  );
};

export default ProductAttributes;
