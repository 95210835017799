import React, { Fragment, useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/react-hooks";

import gql from "graphql-tag";

import { searchInput } from "../../actions/uiStateActions";

import { Container } from "reactstrap";

import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";
import ProductItem from "../../components/ProductSection/ProductItem/ProductItem";
import ProductsContainer from "../../components/ProductSection/ProductsContainer/ProductsContainer";
import ProductsLoader from "../../components/ProductSection/ProductsContainer/ProductsLoader";
import ProductSectionTitle from "../../components/ProductSection/ProductSectionTitle/ProductSectionTitle";
import ProductTitleLoader from "../../components/ProductSection/ProductSectionTitle/ProductTitleLoader";
import ThemeSection from "../../components/ThemeSection/ThemeSection";

import { loadMoreTexts } from "../../assets/Helpers";

const queryString = require("query-string");

const SEARCH_QUERY = gql`
  query ProductSearch($limit: Int, $search: String, $after: String) {
    products(where: { search: $search }, first: $limit, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        productId
        ... on SimpleProduct {
          id
          image {
            id
            sourceUrl(size: MEDIUM)
          }
          name
          slug
          onSale
          salePrice
          regularPrice
          stockStatus
        }
      }
    }
  }
`;

const Search = (props) => {
  const { t } = useTranslation();

  const [buttonText, setButtonText] = useState(loadMoreTexts.default),
    limit = 12,
    isArchive = true,
    dispatch = useDispatch(),
    params = queryString.parse(props.location.search),
    searchParam = params.s;

  const { loading, error, data, fetchMore } = useQuery(SEARCH_QUERY, {
    variables: {
      after: null,
      limit: limit,
      search: searchParam
    }
  });

  useEffect(() => {
    if (searchParam) {
      dispatch(searchInput(searchParam));
    }
  });

  if (error) {
    return (
      <div className="SearchContainer">
        <Container>
          <Fragment>
            <ThemeSection>
              <p>{t("description.please_try_again")}</p>
            </ThemeSection>
          </Fragment>
        </Container>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="SearchContainer">
        <Container>
          <Fragment>
            <ThemeSection>
              <ProductTitleLoader />
              <ProductsLoader total={limit} />
            </ThemeSection>
          </Fragment>
        </Container>
      </div>
    );
  }

  const products = data.products.nodes;

  if (products.length === 0) {
    return (
      <div className="SearchContainer">
        <Container>
          <Fragment>
            <ThemeSection>
              <ProductSectionTitle
                isArchive={isArchive}
                title={t("description.product_not_found")}
              />
            </ThemeSection>
          </Fragment>
        </Container>
      </div>
    );
  }

  const handleClick = () => {
    setButtonText(loadMoreTexts.loading);

    fetchMore({
      variables: {
        after: data.products.pageInfo.endCursor
          ? data.products.pageInfo.endCursor
          : null
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        if (newNodes.length) {
          setButtonText(loadMoreTexts.default);
        }

        return newNodes.length
          ? {
              productCategories: data.productCategories,
              products: {
                __typename: previousResult.products.__typename,
                nodes: [...previousResult.products.nodes, ...newNodes],
                pageInfo
              }
            }
          : previousResult;
      }
    });
  };

  return (
    <div className="SearchContainer">
      <Container>
        <Fragment>
          <ThemeSection>
            <ProductSectionTitle
              isArchive={isArchive}
              title={t("description.search_results_for") + searchParam}
            />
            <ProductsContainer>
              {products.map((product) => {
                return <ProductItem key={product.productId} {...product} />;
              })}
            </ProductsContainer>
          </ThemeSection>
          {isArchive && data.products.pageInfo.hasNextPage && (
            <LoadMoreButton
              title={t(buttonText)}
              onClick={() => handleClick()}
            />
          )}
        </Fragment>
      </Container>
    </div>
  );
};

export default Search;
