import gql from "graphql-tag";

export const GET_THEME_OFFERS_QUERY = gql`
  query getThemeOffersQuery {
    themeOptions {
      themeOffers {
        offersList {
          offerImage {
            mediaItemUrl
          }
          offerLink
        }
        showOffers
      }
    }
  }
`;

export const GET_HEADER_ALERT_QUERY = gql`
  query getHeaderAlertQuery {
    themeOptions {
      headerInformation {
        alertMessage
      }
    }
  }
`;
