import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "react-apollo";

import gql from "graphql-tag";

import v4 from "uuid/v4";

import { Form } from "reactstrap";

import CheckoutFormInput from "../../components/GeneralForm/CheckoutFormInput";
import GeneralFormInput from "../../components/GeneralForm/GeneralFormInput";
import UserSettings from "../../components/UserSettings/UserSettings";
import UserSettingsTitle from "../../components/UserSettings/UserSettingsTitle/UserSettingsTitle";

import { localUser } from "../../assets/Helpers";

import "../../styles/general/_form.scss";

import ResetPasswordButton from "./ResetPasswordButton/ResetPasswordButton";

const UPDATE_USER_MUTATION = gql`
  mutation updateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
const USER_PROFILE_DATA = gql`
  query userProfile($id: ID!) {
    user(id: $id) {
      id
      lastName
      firstName
      email
      userId
    }
  }
`;

const Profile = () => {
  const { t } = useTranslation();
  const userInfo = localUser();
  const [profileData, setProfileData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    passwordConfirm: "",
    isUpdated: false,
    updateProfile: false,
    updatePassword: false,
    profileValidateLabel: "",
    passwordValidateLabel: ""
  });

  const {
    data: dataProfile,
    error: errorProfile,
    loading: loadingProfile
  } = useQuery(USER_PROFILE_DATA, {
    variables: {
      id: userInfo.ID
    }
  });

  const [profile, { data: mutationData, loading: mutationLoading }] =
    useMutation(UPDATE_USER_MUTATION);

  const handleProfileSubmit = (e) => {
    e.preventDefault();

    const { id, firstName, lastName } = profileData;
    profile({
      variables: {
        input: {
          clientMutationId: v4(),
          id: id,
          firstName: firstName,
          lastName: lastName
        }
      }
    });
    if (profileData.updateProfile) {
      setTimeout(function (e) {
        handleProfileDataChange(
          "profileValidateLabel",
          t("description.profile_updated")
        );
      }, 1000);

      handleProfileDataChange("updateProfile", false);

      setTimeout(function (e) {
        handleProfileDataChange("passwordValidateLabel", "");
      }, 2000);
    }
  };

  if (!profileData.isLoading && dataProfile) {
    setProfileData({
      ...profileData,
      id: dataProfile.user.id,
      firstName: dataProfile.user.firstName,
      lastName: dataProfile.user.lastName,
      email: dataProfile.user.email,
      isLoading: true
    });
  }

  const handleProfileChange = (e) => {
    e.target.className = "form-control";
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value
    });
  };

  const handleProfileDataChange = (name, value) => {
    setProfileData({
      ...profileData,
      [name]: value
    });
  };

  const handleOnBlur = (e) => {
    if (e.target.value + "" === "") {
      e.target.className = "form-control error";
    }
    if (e.target.name === "password" || e.target.name === "passwordConfirm") {
      handleProfileDataChange("updatePassword", true);
    } else {
      handleProfileDataChange("updateProfile", true);
    }
  };

  return (
    <div className="Profile">
      <UserSettings>
        <UserSettingsTitle title={t("title.my_account")} />
        <Form
          className="leftSideForm"
          onSubmit={(e) => {
            handleProfileSubmit(e);
          }}
        >
          <h5>{t("title.update")}</h5>
          <CheckoutFormInput
            name="firstName"
            required
            id="firstName"
            type="text"
            placeholder={t("placeholder.name")}
            onChange={(e) => handleProfileChange(e)}
            value={profileData.firstName || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
          <CheckoutFormInput
            name="lastName"
            required
            id="lastName"
            type="text"
            placeholder={t("placeholder.surname")}
            onChange={(e) => handleProfileChange(e)}
            value={profileData.lastName || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
          <GeneralFormInput
            name="email"
            type="email"
            disabled={true}
            placeholder={t("placeholder.email")}
            value={profileData.email || ""}
          />
          <div
            className={
              "GeneralForm__input" +
              (!profileData.updateProfile
                ? " GeneralForm__input--disabled"
                : "")
            }
          >
            <input
              value={t("title.update")}
              type="submit"
              disabled={!profileData.updateProfile}
            />
            <p>{profileData.profileValidateLabel}</p>
          </div>
        </Form>
        <ResetPasswordButton userEmail={profileData.email} />
      </UserSettings>
    </div>
  );
};

export default Profile;
