import React from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/react-hooks";

import gql from "graphql-tag";

import NavigationButton from "../../components/Buttons/NavigationButton/NavigationButton";
import UserSettings from "../../components/UserSettings/UserSettings";
import UserSettingsTitle from "../../components/UserSettings/UserSettingsTitle/UserSettingsTitle";

import "./Orders.scss";

import Order from "./Order/Order";

const USER_ORDERS = gql`
  query UserOrders {
    customer {
      orders(first: 100) {
        nodes {
          id
          date
          total
          status
          orderId
          orderNumber
          dateCompleted
          customer {
            id
            firstName
          }
        }
      }
    }
  }
`;

const Orders = () => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(USER_ORDERS);

  if (loading) {
    return (
      <div className="Orders">
        <UserSettings>
          <UserSettingsTitle title={t("title.orders")} />
          <div className="Orders__container">
            <p>{t("title.loading")}</p>
          </div>
        </UserSettings>
      </div>
    );
  }

  if (error) {
    return (
      <div className="Orders">
        <UserSettings>
          <UserSettingsTitle title={t("title.orders")} />
          <div className="Orders__container">
            <p>{error.message}</p>
          </div>
        </UserSettings>
      </div>
    );
  }

  if (data && data.customer.orders.nodes.length === 0) {
    return (
      <div className="Orders Orders--none">
        <UserSettings>
          <UserSettingsTitle title={t("title.orders")} />
          <p className="Orders__message">{t("description.no_orders")}</p>
          <NavigationButton to="/" title={t("add_products")} />
        </UserSettings>
      </div>
    );
  }

  return (
    <div className="Orders">
      <UserSettings>
        <UserSettingsTitle title={t("title.orders")} />
        <div className="Orders__container">
          {data.customer.orders.nodes.map((order) => {
            return <Order key={order.id} {...order} />;
          })}
        </div>
      </UserSettings>
    </div>
  );
};

export default Orders;
