import { combineReducers } from "redux";

import cartReducer from "./cartReducer";
import uiStateReducer from "./uiStateReducer";
import userReducer from "./userReducer";

const rootReducers = combineReducers({
  cart: cartReducer,
  user: userReducer,
  uiState: uiStateReducer
});

export default rootReducers;
