import React from "react";

import { NavLink } from "react-router-dom";

import "./NavigationButton.scss";

const NavigationButton = (props) => {
  return (
    <NavLink to={props.to || "/"} className="NavigationButton">
      {props.title}
    </NavLink>
  );
};

export default NavigationButton;
