import React, { useState } from "react";

import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "react-apollo";

import gql from "graphql-tag";

import v4 from "uuid/v4";

import { emptyCart } from "../../actions/cartActions";

import { Alert, Col, Form, Row } from "reactstrap";

import {
  getSpecialRequestsString,
  removeSpecialRequests
} from "../../helpers/productSpecialRequest";

import { localUser } from "../../assets/Helpers";

import BillingDetails from "./BillingDetails/BillingDetails";
import CardCheckout from "./CardCheckout/CardCheckout";
import Payment from "./Payment/Payment";
import ShippingDetails from "./ShippingDetails/ShippingDetails";
import TotalCheckout from "./TotalCheckout/TotalCheckout";

const CHECKOUT_MUTATION = gql`
  mutation checkoutMutation($inputData: CheckoutInput!) {
    checkout(input: $inputData) {
      clientMutationId
      order {
        id
        paymentMethod
        orderId
      }
      redirect
      result
    }
  }
`;

const USER_BILLING_DATA = gql`
  query billingQuery($id: ID) {
    customer(id: $id) {
      id
      firstName
      billing {
        firstName
        lastName
        address1
        address2
        city
        phone
        email
        state
        country
        company
        postcode
      }
    }
  }
`;
const USER_SHIPPING_DATA = gql`
  query shippingQuery($id: ID) {
    customer(id: $id) {
      id
      shipping {
        firstName
        lastName
        address1
        address2
        city
        phone
        email
        country
        state
        company
        postcode
      }
    }
  }
`;

const CheckoutForm = (props) => {
  const { t } = useTranslation();
  const userInfo = localUser();
  const dispatch = useDispatch();

  const [checkoutData, setCheckoutData] = useState({
      billing: {
        firstName: "",
        lastName: "",
        company: "",
        zone: "",
        address: "",
        telephone: "",
        state: "",
        country: "AL",
        email: "",
        isLoaded: false
      },
      shipping: {
        firstName: "",
        lastName: "",
        company: "",
        zone: "",
        address: "",
        state: "",
        country: "AL",
        differentShipping: false,
        isLoaded: false
      },
      comment: "",
      payment: {
        method: ""
      },
      isValid: false,
      isOrderUpdated: false,
      shippingAttributes: {
        text: "title.free",
        cost: "0"
      },
      formBankData: null
    }),
    [hasErrors, setHasErrors] = useState(false);

  const {
    data: dataBilling,
    error: errorBilling,
    loading: loadingBilling
  } = useQuery(USER_BILLING_DATA, {
    variables: {
      id: userInfo.ID
    }
  });

  const {
    loading: loadingShipping,
    error: errorShipping,
    data: dataShipping
  } = useQuery(USER_SHIPPING_DATA, {
    variables: {
      id: userInfo.ID
    }
  });

  const [
    checkout,
    { data: mutationData, error: mutationError, loading: mutationLoading }
  ] = useMutation(CHECKOUT_MUTATION);

  if (loadingBilling || loadingShipping || mutationLoading) {
    return <p>{t("title.loading")}</p>;
  }

  if (mutationError || errorShipping || errorBilling) {
    return (
      <div>
        <p>{t("title.please_try_again")}</p>
        <div
          className="CartTotals__buttons"
          style={{ justifyContent: "flex-start" }}
        >
          <NavLink to="/" className="CartButtons__button">
            {t("title.go_back").toUpperCase()}
          </NavLink>
        </div>
      </div>
    );
  }

  if (mutationData && mutationData.checkout.result) {
    removeSpecialRequests();
    dispatch(emptyCart());
    props.history.push("/porosia-sukses/");
  }

  const handleBillingChange = (name, value) => {
    setCheckoutData({
      ...checkoutData,
      billing: {
        ...checkoutData.billing,
        [name]: value
      }
    });
  };

  const handleShippingChange = (name, value) => {
    setCheckoutData({
      ...checkoutData,
      shipping: {
        ...checkoutData.shipping,
        [name]: value
      }
    });
  };

  const toggleShipping = () => {
    setCheckoutData({
      ...checkoutData,
      shipping: {
        ...checkoutData.shipping,
        differentShipping: !checkoutData.shipping.differentShipping
      }
    });
  };

  const handlePaymentChange = (value) => {
    setCheckoutData({
      ...checkoutData,
      payment: {
        ...checkoutData.payment,
        method: value
      }
    });
  };

  const handleCheckoutDataChange = (key, value) => {
    setCheckoutData({
      ...checkoutData,
      [key]: value
    });
  };

  const handleCheckoutSubmit = async (e) => {
    e.preventDefault();

    if (!validFields()) {
      setHasErrors(true);
      window.scrollTo(0, 0);
      return;
    }
    const { billing, shipping, payment, comment } = checkoutData;
    try {
      // get special requests
      const productIds = props.cartData.cart.contents.nodes.map(
        (x) => x.product.productId
      );
      const specialRequests = getSpecialRequestsString(productIds);
      await checkout({
        variables: {
          inputData: {
            clientMutationId: v4(),
            billing: {
              firstName: billing.firstName,
              lastName: billing.lastName,
              phone: billing.telephone,
              address1: billing.address,
              company: billing.company,
              country: "XK",
              state: billing.state,
              city: billing.zone,
              email: billing.email,
              postcode: "10000"
            },
            metaData: [
              { key: "order_note", value: comment },
              { key: "order_specifics", value: specialRequests }
            ],
            shipping: {
              firstName: shipping.firstName,
              lastName: shipping.lastName,
              address1: shipping.address,
              company: shipping.company,
              city: shipping.zone,
              postcode: "10000"
            },
            paymentMethod: payment.method,
            shippingMethod: "free_shipping:1"
          }
        }
      });
    } catch (error) {}
  };

  if (!checkoutData.billing.isLoading && dataBilling) {
    const billingInfo = dataBilling.customer.billing;
    if (billingInfo) {
      setCheckoutData({
        ...checkoutData,
        billing: {
          ...checkoutData.billing,
          firstName: billingInfo.firstName,
          lastName: billingInfo.lastName,
          company: billingInfo.company,
          zone: billingInfo.city,
          country: billingInfo.country,
          address: billingInfo.address1,
          apartment: billingInfo.address2,
          telephone: billingInfo.phone,
          email: billingInfo.email,
          isLoading: true
        }
      });
    }
  }

  if (!checkoutData.shipping.isLoading && dataShipping) {
    const shippingInfo = dataShipping.customer.shipping;
    if (shippingInfo) {
      setCheckoutData({
        ...checkoutData,
        shipping: {
          ...checkoutData.shipping,
          firstName: shippingInfo.firstName,
          lastName: shippingInfo.lastName,
          company: shippingInfo.company,
          zone: shippingInfo.city,
          address: shippingInfo.address1,
          apartment: shippingInfo.address2,
          telephone: shippingInfo.phone,
          email: shippingInfo.email,
          isLoading: true
        }
      });
    }
  }

  const validField = (input) => {
    return input !== "";
  };

  const validFields = () => {
    const { billing, shipping } = checkoutData;

    const validBillingFields =
      validField(billing.firstName) &&
      validField(billing.lastName) &&
      validField(billing.zone) &&
      validField(billing.address) &&
      validField(billing.telephone) &&
      validField(billing.email);

    const validShippingFields =
      validField(shipping.firstName) &&
      validField(shipping.lastName) &&
      validField(shipping.zone) &&
      validField(shipping.address) &&
      validField(shipping.telephone) &&
      validField(shipping.email);

    if (shipping.differentShipping) {
      return validBillingFields && validShippingFields;
    }

    return validBillingFields;
  };

  return (
    <div className="GeneralForm">
      {hasErrors && (
        <Alert color="warning">
          {t("description.please_fill_all_fields_below")}
        </Alert>
      )}

      <Form
        onSubmit={(e) => {
          handleCheckoutSubmit(e);
        }}
      >
        <Row>
          <Col xs="12" sm="12" lg="6">
            <BillingDetails
              details={checkoutData.billing}
              onChange={(name, value) => handleBillingChange(name, value)}
            />
          </Col>
          <Col xs="12" sm="12" lg="6">
            <ShippingDetails
              details={checkoutData.shipping}
              comment={checkoutData.comment}
              toggleShipping={(toggle) => toggleShipping()}
              onChange={(name, value) => handleShippingChange(name, value)}
              onChangeComment={(name, value) => {
                handleCheckoutDataChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" sm="12">
            <p>&nbsp;</p>
          </Col>
          <Col xs="12" sm="6">
            <Payment onChange={(value) => handlePaymentChange(value)} />
          </Col>
          <Col xs="12" sm="12">
            <p>&nbsp;</p>
          </Col>
          <Col xs="12" sm="12" lg="8" xl="9">
            <CardCheckout cartData={props.cartData} />
          </Col>
          <Col xs="12" sm="12" lg="4" xl="3">
            <TotalCheckout
              shippingAttributes={checkoutData.shippingAttributes}
              subTotal={props.cartData.cart.subtotal}
              total={props.cartData.cart.total}
              isValid={checkoutData.isValid}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default withRouter(CheckoutForm);
