import React from "react";

import {
  LazyLoadImage,
  trackWindowScroll
} from "react-lazy-load-image-component";

const ProductItemThumb = ({ src, alt, scrollPosition }) => {
  return (
    <div className="ProductItemThumb">
      <LazyLoadImage
        width="400"
        height="400"
        effect="blur"
        alt={alt}
        src={src}
        scrollPosition={scrollPosition}
      />
    </div>
  );
};

export default trackWindowScroll(ProductItemThumb);
