import React from "react";

import GoogleMapReact from "google-map-react";

import "./GoogleMaps.scss";

import { PristinaDelimiters } from "./GoogleDelimiter";

const apiIsLoaded = (map, maps) => {
  const PristinaPolygon = new maps.Polygon({
    paths: PristinaDelimiters,
    strokeColor: "#ff0000",
    strokeOpacity: 0.7,
    strokeWeight: 2,
    fillColor: "#ff0000",
    fillOpacity: 0.2
  });

  PristinaPolygon.setMap(map);
};

const GoogleMaps = () => {
  return (
    <div className="GoogleMaps">
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: "AIzaSyCxxX74N5ItPCWtbsTu_YqmFNfgiTT_CPI" }}
        defaultCenter={{
          lat: 42.660369,
          lng: 21.1625923
        }}
        defaultZoom={10}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
      />
    </div>
  );
};

export default GoogleMaps;
