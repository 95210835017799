import React from "react";

import ContentLoader from "react-content-loader";

const HomeSliderLoader = () => (
  <ContentLoader
    speed={2}
    width={1100}
    height={300}
    viewBox="0 0 1200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="100" y="16" rx="0" ry="0" width="1047" height="300" />
  </ContentLoader>
);

export default HomeSliderLoader;
