import React from "react";

import { useTranslation } from "react-i18next";

import { Col, Row } from "reactstrap";

import "./ShippingDetails.scss";

import CheckoutFormInput from "../../GeneralForm/CheckoutFormInput";

const ShippingDetails = (props) => {
  const { details, comment } = props;
  const { t } = useTranslation();

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    e.target.className = "form-control";
    props.onChange(name, value);
  };

  const handleUpdateComment = (e) => {
    const { name, value } = e.target;

    e.target.className = "form-control";
    props.onChangeComment(name, value);
  };

  const handleOnBlur = (e) => {
    if (e.target.value + "" === "") {
      e.target.className = "form-control error";
    }
  };

  return (
    <div className="ShippingDetails">
      {props.shippingTitle ? (
        <div className="CheckoutTitle">
          <h5>{t("title.address_details")}</h5>
        </div>
      ) : (
        <div className="CheckoutTitle mb6">
          {/* TODO: Input type checkbox is not looking like a checkbox at all it is looking like a text inoput */}
          <CheckoutFormInput
            label={t("placeholder.send_to_other_shipping_address")}
            type="checkbox"
            id="differentShipping"
            name="differentShipping"
            checked={details.differentShipping || false}
            value={details.differentShipping || false}
            onChange={(e) => props.toggleShipping()}
          />
        </div>
      )}
      {details.differentShipping && (
        <Row>
          <Col xs="12" sm="6">
            <CheckoutFormInput
              label={t("placeholder.first_name")}
              required
              id="firstName"
              name="firstName"
              type="text"
              onChange={(e) => handleUpdate(e)}
              value={details.firstName || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
          <Col xs="12" sm="6">
            <CheckoutFormInput
              label={t("placeholder.last_name")}
              required
              type="text"
              id="lastName"
              name="lastName"
              onChange={(e) => handleUpdate(e)}
              value={details.lastName || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
          <Col xs="12" sm="12">
            <CheckoutFormInput
              label={t("placeholder.address")}
              required
              type="text"
              id="address"
              name="address"
              onChange={(e) => handleUpdate(e)}
              value={details.address || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
        </Row>
      )}
      {!props.shippingTitle && (
        <Row>
          <Col xs="12" sm="12">
            <CheckoutFormInput
              label={t("placeholder.comment")}
              type="textarea"
              id="comment"
              name="comment"
              value={comment || ""}
              onChange={(e) => handleUpdateComment(e)}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ShippingDetails;
