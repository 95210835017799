import React, { useEffect, useState } from "react";

import { NavLink, withRouter } from "react-router-dom";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { useMutation } from "react-apollo";

import gql from "graphql-tag";

import ReCAPTCHA from "react-google-recaptcha";

import { addUserInfo } from "../../../actions/userActions";

import { Alert, Form } from "reactstrap";

import GeneralFormInput from "../../../components/GeneralForm/GeneralFormInput";

import "../../../styles/general/_form.scss";

const queryString = require("query-string");

const LOGIN_MUTATION = gql`
  mutation LoginUser(
    $username: String!
    $password: String!
    $captcha: String!
  ) {
    login(
      input: {
        clientMutationId: "loginFormID"
        username: $username
        password: $password
        captcha: $captcha
      }
    ) {
      authToken
      refreshToken
      user {
        id
        name
        firstName
        lastName
        jwtAuthToken
        userId
      }
    }
  }
`;

const LoginForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const params = queryString.parse(props.location.search);
  const [login, { loading, error, data }] = useMutation(LOGIN_MUTATION);

  const [loginData, setLoginData] = useState({
    username: "",
    password: ""
  });

  const [hasErrors, toggleErrors] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const isDisabled = !loginData.username || !loginData.password || loading,
    invalidLogin = error && error.message;

  useEffect(() => {
    if (error && error.message) {
      recaptchaRef.current.props.grecaptcha.reset();
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value
    });
  };

  useEffect(() => {
    if (data && data.login.authToken) {
      const userData = data.login;

      const userInfo = {
        ID: userData.user.id,
        NAME: userData.user.firstName,
        AUTHTOKEN: userData.authToken,
        REFRESH_TOKEN: userData.refreshToken,
        userId: userData.user.userId
      };

      localStorage.setItem("SHOP_USER", JSON.stringify(userInfo));

      dispatch(
        addUserInfo({
          id: userInfo.ID,
          isLogged: true,
          firstName: data.login.user.firstName,
          userId: data.login.user.userId
        })
      );

      if (params.proceso) {
        props.history.replace("/proceso-porosine");
      } else {
        props.history.replace("/");
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="GeneralForm">
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          const recaptchaValue = recaptchaRef.current.getValue();

          if (!recaptchaValue) {
            toggleErrors(true);
            return;
          } else {
            toggleErrors(false);
          }

          login({
            variables: {
              ...loginData,
              captcha: recaptchaValue
            }
          });
        }}
      >
        {invalidLogin && (
          <Alert color="warning">{t("validator.invalid_credentials")}</Alert>
        )}

        {hasErrors && !captchaToken && (
          <Alert color="warning">{t("description.captcha_required")}</Alert>
        )}

        <GeneralFormInput
          type="text"
          name="username"
          placeholder={t("placeholder.email")}
          value={loginData.username || ""}
          onChange={(e) => handleChange(e)}
        />
        <GeneralFormInput
          name="password"
          type="password"
          placeholder={t("placeholder.password")}
          onChange={(e) => handleChange(e)}
          value={loginData.password || ""}
        />

        <p className="GeneralForm__text text-right">
          <NavLink to="/perditeso-fjalekalimin">
            {t("title.forgot_password")}
          </NavLink>
        </p>
        <div className="GeneralForm__input GeneralForm__input--recaptcha">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lc05c4UAAAAAHOXA390-IgP62d7iSFXP2EgqHUV"
            onChange={(e) => {
              setCaptchaToken(e);
            }}
          />
        </div>
        <div
          className={
            "GeneralForm__input" +
            (isDisabled ? " GeneralForm__input--disabled" : "")
          }
        >
          <input
            value={loading ? t("title.logging_in") : t("title.login")}
            type="submit"
            disabled={isDisabled}
          />
        </div>
        <p
          className="GeneralForm__text text-center"
          style={{ marginTop: "10px" }}
        >
          <NavLink to="/regjistrohu">
            {t("description.no_account_register_here")}
          </NavLink>
        </p>
      </Form>
    </div>
  );
};

export default withRouter(LoginForm);
