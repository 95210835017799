import React from "react";

import { Col, Row } from "reactstrap";

import "./ListedProductsList.scss";

import ProductItem from "../../ProductSection/ProductItem/ProductItem";

export const ListedProductsList = (props) => {
  const products = props.products;

  return (
    <Row>
      <div className="ListedProductsList mt-3">
        {products.map((product) => {
          return (
            <Col className="col-md-3" sm="12" key={product.id}>
              <ProductItem {...product} />
            </Col>
          );
        })}
      </div>
    </Row>
  );
};
