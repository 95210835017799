import React from "react";

import { useTranslation } from "react-i18next";

import { Container } from "reactstrap";

import "./Home.scss";

import { HomeOffers } from "./HomeOffers/HomeOffers";
import { HomeProducts } from "./HomeProducts/HomeProducts";
import { HomeSlider } from "./HomeSlider/HomeSlider";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <HomeOffers />
        <div className="Home__image mt-4 mb-5">
          {/* <img src={homeImage} alt="Porositë falas" /> */}
          <HomeSlider />
        </div>
        <HomeProducts title={t("title.selected_just_for_you")} />
      </Container>
    </>
  );
};

export default Home;
