import React from "react";

import { Container } from "reactstrap";

import searchIconWhite from "../../assets/images/search-icon-white.svg";

import "./TopHeader.scss";

import Logo from "./Logo/Logo";
import UserNavigation from "./UserNavigation/UserNavigation";

const TopHeader = (props) => {
  // const hamburgerClass = classNames("TopHeader__hamburger", {
  //   "TopHeader__hamburger--active": props.isOpen
  // });

  const isMobile = window.innerWidth < 768;

  return (
    <div className="TopHeader py-3">
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <Logo />
          <div className="d-flex align-items-center">
            <UserNavigation />

            {isMobile && (
              <img
                src={searchIconWhite}
                alt="Search icon"
                onClick={() => props.setShowSearchInput((prev) => !prev)}
              />
            )}

            {/* Hamburger button */}
            {/* <div className={hamburgerClass} onClick={() => props.toggleNav()}>
              <span></span>
              <span></span>
              <span></span>
            </div> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopHeader;
