export const initCart = (values) => {
  return {
    type: "INIT_CART",
    payload: values
  };
};

export const addIdToCart = (id) => {
  return {
    type: "ADD_TO_CART",
    payload: id
  };
};

export const addItemsToCart = (values) => {
  return {
    type: "ADD_ITEMS_TO_CART",
    payload: values
  };
};

export const removeFromCart = (id) => {
  return {
    type: "REMOVE_ITEM",
    payload: id
  };
};

export const emptyCart = () => {
  return {
    type: "EMPTY_CART"
  };
};

export const addItem = () => {
  return {
    type: "ADD_ITEM"
  };
};
