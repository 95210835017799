import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { useMutation } from "react-apollo";

import gql from "graphql-tag";

import ReCAPTCHA from "react-google-recaptcha";
import v4 from "uuid/v4";

import { Alert, Form } from "reactstrap";

import FormModal from "../../../components/FormModal/FormModal";
import FormTitle from "../../../components/FormModal/FormTitle/FormTitle";
import GeneralFormInput from "../../../components/GeneralForm/GeneralFormInput";

import "../ForgotPassword.scss";

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      clientMutationId
    }
  }
`;

const ResetPasswordEmail = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    firstname: ""
  });
  const recaptchaRef = React.createRef();
  const [hasErrors, toggleErrors] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const [
    sendPasswordResetEmail,
    { data: resetData, loading: loadingReset, error: resetError }
  ] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  if (loadingReset) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">{t("title.please_wait")}</p>
      </FormModal>
    );
  }

  if (resetData) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">
          {t("description.password_reset_link_sent")}
        </p>
      </FormModal>
    );
  }

  if (resetError) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">
          {resetError.message.replace("GraphQL error: ", "")}
        </p>
      </FormModal>
    );
  }

  const isDisabled = formData.firstname === "" || loadingReset;

  return (
    <FormModal halfModal={true}>
      <FormTitle title={t("title.update_password")} />
      <div className="GeneralForm">
        {hasErrors && !captchaToken && (
          <Alert color="warning">{t("description.captcha_required")}</Alert>
        )}
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            if (!recaptchaRef) {
              toggleErrors(true);
              return;
            }

            const recaptchaValue = recaptchaRef.current.getValue();

            if (!recaptchaValue) {
              toggleErrors(true);
              return;
            } else {
              toggleErrors(false);
            }

            sendPasswordResetEmail({
              variables: {
                input: {
                  clientMutationId: v4(),
                  username: formData.firstname,
                  captcha: recaptchaValue
                }
              }
            });
          }}
        >
          <GeneralFormInput
            name="firstname"
            type="text"
            placeholder={t("placeholder.email")}
            onChange={(e) => handleChange(e)}
            value={formData.firstname || ""}
          />
          <div className="GeneralForm__input GeneralForm__input--recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lc05c4UAAAAAHOXA390-IgP62d7iSFXP2EgqHUV"
            />
          </div>
          <div
            className={
              "GeneralForm__input" +
              (isDisabled ? " GeneralForm__input--disabled" : "")
            }
          >
            <input
              type="submit"
              disabled={isDisabled}
              value={loadingReset ? t("title.sending") : t("title.send")}
            />
          </div>
        </Form>
      </div>
    </FormModal>
  );
};

export default ResetPasswordEmail;
