import React from "react";

import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Col, Row } from "reactstrap";

import "./BillingDetails.scss";

import CheckoutFormInput from "../../GeneralForm/CheckoutFormInput";

const BillingDetails = (props) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.user);

  const { details } = props;

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    e.target.className = "form-control";
    props.onChange(name, value);
  };

  const handleOnBlur = (e) => {
    if (e.target.value + "" === "") {
      e.target.className = "form-control error";
    }
  };

  return (
    <div className="BillingDetails">
      <div className="CheckoutTitle">
        {!userInfo.isLogged && (
          <div>
            <h5>
              {t("description.fill_in_the_fields_or")}
              <NavLink to="/kyqu/?proceso=porosine">
                {t("title.login_here")}
              </NavLink>
            </h5>
            {/* <h5>
              {t("description.click_for_auto_location")} &nbsp;
              <button
                type="button"
                style={{ padding: "0 20px" }}
                onClick={props.getLocation}
                className="btn btn-dark"
              >
                {t("title.here")}
              </button>
            </h5> */}
          </div>
        )}
      </div>
      <Row>
        <Col xs="12" sm="6">
          <CheckoutFormInput
            label={t("placeholder.first_name")}
            required
            id="firstName"
            name="firstName"
            type="text"
            onChange={(e) => handleUpdate(e)}
            value={details.firstName || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
        </Col>
        <Col xs="12" sm="6">
          <CheckoutFormInput
            label={t("placeholder.last_name")}
            required
            type="text"
            id="lastName"
            name="lastName"
            onChange={(e) => handleUpdate(e)}
            value={details.lastName || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
        </Col>
        <Col xs="12" sm="12">
          <CheckoutFormInput
            label={t("placeholder.address")}
            required
            type="text"
            id="address"
            name="address"
            onChange={(e) => handleUpdate(e)}
            value={details.address || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
        </Col>
        <Col xs="12" sm="12">
          <CheckoutFormInput
            label={t("placeholder.phone")}
            required
            type="text"
            id="telephone"
            name="telephone"
            onChange={(e) => handleUpdate(e)}
            value={details.telephone || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
        </Col>
        {details.guestCheckout && (
          <Col xs="12" sm="12">
            <CheckoutFormInput
              label={t("placeholder.email")}
              required
              type="email"
              id="email"
              name="email"
              onChange={(e) => handleUpdate(e)}
              value={details.email || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default BillingDetails;
