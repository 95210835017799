export const PristinaDelimiters = [
  { lng: 21.321039199829, lat: 42.5700569152833 },
  { lng: 21.3125362396241, lat: 42.5736122131348 },
  { lng: 21.3046894073486, lat: 42.5760345458985 },
  { lng: 21.3026714324951, lat: 42.5768432617189 },
  { lng: 21.3001785278321, lat: 42.578468322754 },
  { lng: 21.2977905273438, lat: 42.5809936523438 },
  { lng: 21.2949314117432, lat: 42.5872230529786 },
  { lng: 21.2918014526368, lat: 42.5918388366699 },
  { lng: 21.2876892089844, lat: 42.5958061218263 },
  { lng: 21.2864437103271, lat: 42.5966949462892 },
  { lng: 21.2848949432372, lat: 42.5973205566406 },
  { lng: 21.2831974029542, lat: 42.5976028442384 },
  { lng: 21.2790889739991, lat: 42.5975646972657 },
  { lng: 21.2763233184815, lat: 42.5980491638184 },
  { lng: 21.2735538482665, lat: 42.5990219116211 },
  { lng: 21.2682876586915, lat: 42.6015281677247 },
  { lng: 21.2655048370362, lat: 42.6024589538575 },
  { lng: 21.2626934051514, lat: 42.6028938293458 },
  { lng: 21.2598285675049, lat: 42.6029510498048 },
  { lng: 21.2558746337891, lat: 42.6027450561524 },
  { lng: 21.253101348877, lat: 42.6029243469238 },
  { lng: 21.2510070800781, lat: 42.603401184082 },
  { lng: 21.2490901947021, lat: 42.604175567627 },
  { lng: 21.2474098205568, lat: 42.6052284240723 },
  { lng: 21.2415332794189, lat: 42.6098747253419 },
  { lng: 21.2399005889893, lat: 42.61083984375 },
  { lng: 21.2380771636962, lat: 42.611457824707 },
  { lng: 21.2361316680909, lat: 42.611671447754 },
  { lng: 21.2343635559082, lat: 42.611499786377 },
  { lng: 21.2313823699952, lat: 42.6106147766114 },
  { lng: 21.2208366394044, lat: 42.6055335998536 },
  { lng: 21.2192344665527, lat: 42.6044464111329 },
  { lng: 21.2180347442628, lat: 42.6031951904296 },
  { lng: 21.2172374725342, lat: 42.6018447875978 },
  { lng: 21.2169036865234, lat: 42.6006050109864 },
  { lng: 21.2169475555421, lat: 42.5997200012207 },
  { lng: 21.2177562713622, lat: 42.5978851318359 },
  { lng: 21.2202320098878, lat: 42.5942497253419 },
  { lng: 21.2205867767335, lat: 42.5929374694825 },
  { lng: 21.2205162048341, lat: 42.5916175842286 },
  { lng: 21.2189388275146, lat: 42.5877227783204 },
  { lng: 21.2188472747804, lat: 42.5856437683106 },
  { lng: 21.2217864990236, lat: 42.5802154541016 },
  { lng: 21.2225437164307, lat: 42.5781517028809 },
  { lng: 21.2247734069824, lat: 42.5689544677735 },
  { lng: 21.2246685028076, lat: 42.5675621032716 },
  { lng: 21.2240962982178, lat: 42.5662689208985 },
  { lng: 21.2223415374755, lat: 42.564624786377 },
  { lng: 21.2191352844239, lat: 42.5629653930664 },
  { lng: 21.2047786712648, lat: 42.5590095520021 },
  { lng: 21.2029514312745, lat: 42.5583381652832 },
  { lng: 21.1980056762695, lat: 42.5552635192871 },
  { lng: 21.1957397460938, lat: 42.5545806884766 },
  { lng: 21.1931915283204, lat: 42.5544776916504 },
  { lng: 21.190788269043, lat: 42.5549125671387 },
  { lng: 21.1856803894043, lat: 42.5563049316406 },
  { lng: 21.1820526123047, lat: 42.5568885803224 },
  { lng: 21.1705265045167, lat: 42.5578956604003 },
  { lng: 21.1669235229492, lat: 42.5585861206055 },
  { lng: 21.164972305298, lat: 42.5592269897462 },
  { lng: 21.1612358093263, lat: 42.5609893798829 },
  { lng: 21.1595764160157, lat: 42.5621337890626 },
  { lng: 21.158254623413, lat: 42.5634841918946 },
  { lng: 21.1572170257569, lat: 42.5649871826173 },
  { lng: 21.1543884277344, lat: 42.569751739502 },
  { lng: 21.1523952484131, lat: 42.5713920593263 },
  { lng: 21.1500663757324, lat: 42.5726242065431 },
  { lng: 21.1476936340333, lat: 42.5733375549316 },
  { lng: 21.1466026306152, lat: 42.5733604431152 },
  { lng: 21.1442451477052, lat: 42.5726928710938 },
  { lng: 21.142765045166, lat: 42.5717239379882 },
  { lng: 21.1370487213135, lat: 42.5667686462403 },
  { lng: 21.1295833587648, lat: 42.5625381469728 },
  { lng: 21.1198158264161, lat: 42.5577964782715 },
  { lng: 21.1162052154541, lat: 42.5567970275879 },
  { lng: 21.1142959594727, lat: 42.5567359924318 },
  { lng: 21.1124744415284, lat: 42.5570602416992 },
  { lng: 21.1100616455078, lat: 42.5582237243653 },
  { lng: 21.1073665618896, lat: 42.5603408813477 },
  { lng: 21.1047992706299, lat: 42.5628280639649 },
  { lng: 21.1024475097656, lat: 42.5644149780275 },
  { lng: 21.0998134613038, lat: 42.5656852722169 },
  { lng: 21.096471786499, lat: 42.5666427612305 },
  { lng: 21.0942363739014, lat: 42.566505432129 },
  { lng: 21.0928764343262, lat: 42.5659332275391 },
  { lng: 21.0913085937501, lat: 42.5646133422853 },
  { lng: 21.0865383148193, lat: 42.5587043762207 },
  { lng: 21.0845699310304, lat: 42.5572700500489 },
  { lng: 21.0822658538819, lat: 42.5564994812013 },
  { lng: 21.079984664917, lat: 42.5566673278809 },
  { lng: 21.0784912109376, lat: 42.5573463439941 },
  { lng: 21.072280883789, lat: 42.5628776550293 },
  { lng: 21.0807991027832, lat: 42.5768203735353 },
  { lng: 21.0855255126954, lat: 42.5819549560547 },
  { lng: 21.0878067016602, lat: 42.585163116455 },
  { lng: 21.0915393829347, lat: 42.5928649902345 },
  { lng: 21.0926342010497, lat: 42.5971450805664 },
  { lng: 21.0934715270997, lat: 42.5985946655275 },
  { lng: 21.0946979522705, lat: 42.5998878479004 },
  { lng: 21.0971107482911, lat: 42.6013488769531 },
  { lng: 21.1023654937745, lat: 42.6038322448732 },
  { lng: 21.1042861938478, lat: 42.6052131652832 },
  { lng: 21.1066608428955, lat: 42.6076698303222 },
  { lng: 21.1083278656006, lat: 42.6103401184081 },
  { lng: 21.1086826324463, lat: 42.6118392944336 },
  { lng: 21.1086444854737, lat: 42.6133651733398 },
  { lng: 21.1075534820558, lat: 42.618320465088 },
  { lng: 21.1073093414307, lat: 42.6227836608887 },
  { lng: 21.1068592071533, lat: 42.6241493225098 },
  { lng: 21.1059761047363, lat: 42.6253280639648 },
  { lng: 21.1036987304689, lat: 42.6265678405762 },
  { lng: 21.1017036437989, lat: 42.6270713806153 },
  { lng: 21.0935096740724, lat: 42.6282691955566 },
  { lng: 21.0914516448976, lat: 42.6288604736329 },
  { lng: 21.0898208618163, lat: 42.6297035217286 },
  { lng: 21.0888195037841, lat: 42.6308250427247 },
  { lng: 21.0885124206544, lat: 42.6321220397949 },
  { lng: 21.089189529419, lat: 42.6339836120607 },
  { lng: 21.0924701690674, lat: 42.6388511657715 },
  { lng: 21.0935916900635, lat: 42.6411933898927 },
  { lng: 21.095085144043, lat: 42.6463813781739 },
  { lng: 21.0960865020753, lat: 42.6521224975587 },
  { lng: 21.0963401794434, lat: 42.6528930664063 },
  { lng: 21.0976390838623, lat: 42.6543312072754 },
  { lng: 21.1047649383545, lat: 42.6601219177246 },
  { lng: 21.1089267730714, lat: 42.664608001709 },
  { lng: 21.1121158599854, lat: 42.6694831848145 },
  { lng: 21.1127738952638, lat: 42.6714706420898 },
  { lng: 21.1127834320069, lat: 42.6737785339356 },
  { lng: 21.1117210388184, lat: 42.6757698059081 },
  { lng: 21.1106128692628, lat: 42.6768989562989 },
  { lng: 21.1061992645264, lat: 42.6798896789551 },
  { lng: 21.1043643951415, lat: 42.6815032958985 },
  { lng: 21.0979423522949, lat: 42.6882095336915 },
  { lng: 21.0928688049317, lat: 42.6916809082032 },
  { lng: 21.0914421081542, lat: 42.6933937072754 },
  { lng: 21.090518951416, lat: 42.6954078674316 },
  { lng: 21.0899486541749, lat: 42.6979827880859 },
  { lng: 21.0893115997314, lat: 42.7061309814454 },
  { lng: 21.0888042449952, lat: 42.7087020874023 },
  { lng: 21.0882167816163, lat: 42.7101898193359 },
  { lng: 21.0872993469238, lat: 42.7115249633788 },
  { lng: 21.0851917266846, lat: 42.7131729125977 },
  { lng: 21.0786876678468, lat: 42.7165145874023 },
  { lng: 21.0763931274415, lat: 42.718132019043 },
  { lng: 21.0752410888672, lat: 42.7193984985352 },
  { lng: 21.0708122253419, lat: 42.7260055541993 },
  { lng: 21.0700893402101, lat: 42.728012084961 },
  { lng: 21.0698127746583, lat: 42.7301330566407 },
  { lng: 21.0699043273926, lat: 42.7322921752931 },
  { lng: 21.0704021453858, lat: 42.73441696167 },
  { lng: 21.0727443695069, lat: 42.739315032959 },
  { lng: 21.073944091797, lat: 42.7412567138673 },
  { lng: 21.0763168334961, lat: 42.7437057495118 },
  { lng: 21.0785751342773, lat: 42.7453613281251 },
  { lng: 21.0819644927979, lat: 42.7471656799316 },
  { lng: 21.0841903686524, lat: 42.7487182617187 },
  { lng: 21.0853176116944, lat: 42.7499923706055 },
  { lng: 21.0908050537109, lat: 42.7585525512696 },
  { lng: 21.0935993194581, lat: 42.7619438171388 },
  { lng: 21.0981254577636, lat: 42.7653999328614 },
  { lng: 21.1010742187499, lat: 42.7670440673828 },
  { lng: 21.104742050171, lat: 42.7656707763673 },
  { lng: 21.1119422912598, lat: 42.7623023986818 },
  { lng: 21.1152286529542, lat: 42.7612380981446 },
  { lng: 21.1184043884277, lat: 42.7609176635743 },
  { lng: 21.1212692260743, lat: 42.7615509033203 },
  { lng: 21.1246814727784, lat: 42.7636566162109 },
  { lng: 21.129524230957, lat: 42.7676887512208 },
  { lng: 21.1316776275635, lat: 42.7698898315429 },
  { lng: 21.1372089385986, lat: 42.7764625549318 },
  { lng: 21.1400012969972, lat: 42.7788734436036 },
  { lng: 21.142417907715, lat: 42.7803840637208 },
  { lng: 21.1450424194337, lat: 42.7814979553223 },
  { lng: 21.1471042633057, lat: 42.7819480895997 },
  { lng: 21.1491718292237, lat: 42.7820091247558 },
  { lng: 21.1509552001954, lat: 42.7817001342775 },
  { lng: 21.154411315918, lat: 42.7802963256837 },
  { lng: 21.1558914184571, lat: 42.7792396545411 },
  { lng: 21.1569576263428, lat: 42.7779388427734 },
  { lng: 21.1575927734375, lat: 42.7764625549318 },
  { lng: 21.157814025879, lat: 42.7744827270508 },
  { lng: 21.1575088500978, lat: 42.7716484069825 },
  { lng: 21.1576213836671, lat: 42.769630432129 },
  { lng: 21.1580963134767, lat: 42.7681007385254 },
  { lng: 21.1589031219483, lat: 42.7667045593263 },
  { lng: 21.1606159210206, lat: 42.7651176452637 },
  { lng: 21.1629428863525, lat: 42.76420211792 },
  { lng: 21.1695213317871, lat: 42.7639312744142 },
  { lng: 21.17232131958, lat: 42.7635917663575 },
  { lng: 21.1744804382324, lat: 42.7630615234375 },
  { lng: 21.1764965057374, lat: 42.7622871398926 },
  { lng: 21.1783237457277, lat: 42.7612724304199 },
  { lng: 21.1866302490235, lat: 42.7557716369629 },
  { lng: 21.1897830963136, lat: 42.754264831543 },
  { lng: 21.1915378570556, lat: 42.7539138793945 },
  { lng: 21.1931457519532, lat: 42.7540283203126 },
  { lng: 21.1949348449708, lat: 42.7550888061524 },
  { lng: 21.1976985931398, lat: 42.7594566345216 },
  { lng: 21.1993560791016, lat: 42.7614707946777 },
  { lng: 21.2015895843506, lat: 42.7630767822266 },
  { lng: 21.2044544219972, lat: 42.7639198303223 },
  { lng: 21.2058811187744, lat: 42.7639884948731 },
  { lng: 21.2160701751709, lat: 42.7623405456544 },
  { lng: 21.2210693359375, lat: 42.7599983215333 },
  { lng: 21.2230548858643, lat: 42.7606582641602 },
  { lng: 21.2239093780519, lat: 42.7610054016114 },
  { lng: 21.2242698669434, lat: 42.7612113952636 },
  { lng: 21.2245655059814, lat: 42.7614593505861 },
  { lng: 21.2252597808839, lat: 42.7623023986818 },
  { lng: 21.2258319854737, lat: 42.7631950378419 },
  { lng: 21.2260398864747, lat: 42.7638130187989 },
  { lng: 21.2260894775391, lat: 42.7647972106935 },
  { lng: 21.2260608673095, lat: 42.7669792175293 },
  { lng: 21.2269744873047, lat: 42.7688064575195 },
  { lng: 21.2287292480469, lat: 42.770652770996 },
  { lng: 21.2311611175537, lat: 42.7721252441407 },
  { lng: 21.2332515716553, lat: 42.7727279663087 },
  { lng: 21.2416019439698, lat: 42.77396774292 },
  { lng: 21.2464027404786, lat: 42.7758789062501 },
  { lng: 21.2520923614503, lat: 42.7786865234375 },
  { lng: 21.2545928955079, lat: 42.7802505493164 },
  { lng: 21.2574386596681, lat: 42.7825431823732 },
  { lng: 21.2630558013915, lat: 42.7864685058595 },
  { lng: 21.2671108245851, lat: 42.7908973693848 },
  { lng: 21.2691097259521, lat: 42.7924041748046 },
  { lng: 21.2708435058594, lat: 42.7930908203126 },
  { lng: 21.2727565765381, lat: 42.7934303283693 },
  { lng: 21.2752628326415, lat: 42.7933425903321 },
  { lng: 21.2811698913575, lat: 42.7919082641602 },
  { lng: 21.283109664917, lat: 42.7915840148926 },
  { lng: 21.2859516143798, lat: 42.7914543151856 },
  { lng: 21.2887954711914, lat: 42.7917594909668 },
  { lng: 21.2964496612549, lat: 42.7941970825196 },
  { lng: 21.3010940551758, lat: 42.7953109741211 },
  { lng: 21.3029823303223, lat: 42.7955093383789 },
  { lng: 21.3050842285157, lat: 42.7953758239746 },
  { lng: 21.3071117401124, lat: 42.7948875427247 },
  { lng: 21.3096008300781, lat: 42.7937469482422 },
  { lng: 21.3117752075196, lat: 42.7922172546387 },
  { lng: 21.3161773681642, lat: 42.78816986084 },
  { lng: 21.3181591033936, lat: 42.7862777709962 },
  { lng: 21.3211860656739, lat: 42.7824745178223 },
  { lng: 21.3244132995606, lat: 42.7766647338868 },
  { lng: 21.3260345458985, lat: 42.7750167846681 },
  { lng: 21.3275737762452, lat: 42.7740631103517 },
  { lng: 21.3347969055177, lat: 42.7709693908691 },
  { lng: 21.3397445678711, lat: 42.7696647644043 },
  { lng: 21.3458652496338, lat: 42.7684440612794 },
  { lng: 21.3505039215088, lat: 42.7670593261719 },
  { lng: 21.3533077239991, lat: 42.7657470703125 },
  { lng: 21.3578472137452, lat: 42.7626914978028 },
  { lng: 21.3595314025879, lat: 42.7610778808595 },
  { lng: 21.3607387542725, lat: 42.7593536376954 },
  { lng: 21.3613204956055, lat: 42.7574119567871 },
  { lng: 21.361764907837, lat: 42.7524490356446 },
  { lng: 21.3623542785645, lat: 42.7509231567383 },
  { lng: 21.3693084716797, lat: 42.7371978759766 },
  { lng: 21.3711471557618, lat: 42.7353096008301 },
  { lng: 21.3743171691895, lat: 42.7329177856445 },
  { lng: 21.3772163391113, lat: 42.7314109802247 },
  { lng: 21.3793468475342, lat: 42.7308235168458 },
  { lng: 21.3815441131593, lat: 42.7305793762208 },
  { lng: 21.3844203948976, lat: 42.7307281494141 },
  { lng: 21.3932361602784, lat: 42.732936859131 },
  { lng: 21.3967170715333, lat: 42.7334938049317 },
  { lng: 21.4118328094483, lat: 42.73441696167 },
  { lng: 21.4197006225587, lat: 42.735523223877 },
  { lng: 21.4226799011231, lat: 42.7357711791992 },
  { lng: 21.4256000518799, lat: 42.7357368469239 },
  { lng: 21.4283237457276, lat: 42.7353897094727 },
  { lng: 21.4306640624999, lat: 42.7346343994141 },
  { lng: 21.4372749328613, lat: 42.7299118041993 },
  { lng: 21.4414482116699, lat: 42.7277145385743 },
  { lng: 21.4437713623048, lat: 42.7266998291016 },
  { lng: 21.4461536407472, lat: 42.7260360717773 },
  { lng: 21.4480876922608, lat: 42.7258911132812 },
  { lng: 21.4499816894531, lat: 42.7261161804199 },
  { lng: 21.4526290893555, lat: 42.7270622253419 },
  { lng: 21.4587135314942, lat: 42.7297782897949 },
  { lng: 21.4607601165771, lat: 42.7301979064942 },
  { lng: 21.46293258667, lat: 42.730323791504 },
  { lng: 21.4659061431885, lat: 42.7300415039062 },
  { lng: 21.4686965942383, lat: 42.7292938232422 },
  { lng: 21.4805278778077, lat: 42.7248840332031 },
  { lng: 21.4849643707276, lat: 42.7226219177247 },
  { lng: 21.4784030914306, lat: 42.7102737426758 },
  { lng: 21.4765243530274, lat: 42.7079277038575 },
  { lng: 21.4750843048096, lat: 42.706600189209 },
  { lng: 21.4682903289795, lat: 42.7019424438478 },
  { lng: 21.4662876129151, lat: 42.7002143859863 },
  { lng: 21.464916229248, lat: 42.6983451843262 },
  { lng: 21.4643840789794, lat: 42.6964683532715 },
  { lng: 21.4643592834473, lat: 42.6937599182129 },
  { lng: 21.4640083312988, lat: 42.6918182373047 },
  { lng: 21.4626693725587, lat: 42.6891670227051 },
  { lng: 21.4602699279786, lat: 42.686264038086 },
  { lng: 21.4589653015138, lat: 42.6852798461915 },
  { lng: 21.4566211700441, lat: 42.684154510498 },
  { lng: 21.4548225402832, lat: 42.6838340759277 },
  { lng: 21.4529151916504, lat: 42.6838569641114 },
  { lng: 21.4455337524415, lat: 42.6855163574219 },
  { lng: 21.4360713958741, lat: 42.6860694885253 },
  { lng: 21.43390083313, lat: 42.6865501403809 },
  { lng: 21.4318428039551, lat: 42.6872711181642 },
  { lng: 21.4265022277833, lat: 42.6900177001953 },
  { lng: 21.4244670867921, lat: 42.6908416748047 },
  { lng: 21.4203777313232, lat: 42.69189453125 },
  { lng: 21.4182662963867, lat: 42.6920623779298 },
  { lng: 21.4142360687256, lat: 42.6916122436524 },
  { lng: 21.4027652740479, lat: 42.6891403198243 },
  { lng: 21.4009056091309, lat: 42.6884574890137 },
  { lng: 21.3994770050049, lat: 42.6875686645508 },
  { lng: 21.3986492156983, lat: 42.6864624023438 },
  { lng: 21.3984928131104, lat: 42.6852188110352 },
  { lng: 21.3992156982422, lat: 42.6836891174317 },
  { lng: 21.4006366729736, lat: 42.6821479797364 },
  { lng: 21.404884338379, lat: 42.6789741516114 },
  { lng: 21.4112071990967, lat: 42.6752281188965 },
  { lng: 21.4133453369141, lat: 42.6734962463379 },
  { lng: 21.4150466918945, lat: 42.6706886291504 },
  { lng: 21.4166107177734, lat: 42.6666831970215 },
  { lng: 21.4177551269531, lat: 42.6648063659669 },
  { lng: 21.4194183349609, lat: 42.6632843017578 },
  { lng: 21.4210491180421, lat: 42.6624832153321 },
  { lng: 21.4301834106446, lat: 42.6596565246583 },
  { lng: 21.4415187835693, lat: 42.6542282104493 },
  { lng: 21.4487495422363, lat: 42.6516075134277 },
  { lng: 21.4513702392579, lat: 42.6502647399902 },
  { lng: 21.4529819488527, lat: 42.6489067077637 },
  { lng: 21.4540500640869, lat: 42.6473579406738 },
  { lng: 21.4545154571534, lat: 42.6455001831056 },
  { lng: 21.4542999267579, lat: 42.6436233520507 },
  { lng: 21.4529361724854, lat: 42.6402778625489 },
  { lng: 21.4526309967042, lat: 42.6388053894043 },
  { lng: 21.4528064727784, lat: 42.635871887207 },
  { lng: 21.4538116455078, lat: 42.6309776306152 },
  { lng: 21.4535884857178, lat: 42.6289253234864 },
  { lng: 21.4492282867432, lat: 42.6206474304199 },
  { lng: 21.4477233886719, lat: 42.6165771484375 },
  { lng: 21.4471721649171, lat: 42.6134071350098 },
  { lng: 21.439245223999, lat: 42.6103324890138 },
  { lng: 21.4304428100587, lat: 42.6073799133301 },
  { lng: 21.427547454834, lat: 42.6060066223145 },
  { lng: 21.4244480133057, lat: 42.6039733886719 },
  { lng: 21.421911239624, lat: 42.6029090881347 },
  { lng: 21.4192848205568, lat: 42.602523803711 },
  { lng: 21.4175910949708, lat: 42.6027984619142 },
  { lng: 21.4162425994874, lat: 42.6035118103027 },
  { lng: 21.4154224395753, lat: 42.604549407959 },
  { lng: 21.4123554229737, lat: 42.6150360107422 },
  { lng: 21.4116744995118, lat: 42.6168365478516 },
  { lng: 21.4105281829835, lat: 42.6181297302247 },
  { lng: 21.4086990356446, lat: 42.6186561584473 },
  { lng: 21.4065284729005, lat: 42.618450164795 },
  { lng: 21.40500831604, lat: 42.6179008483887 },
  { lng: 21.4001274108887, lat: 42.6150283813477 },
  { lng: 21.3984432220459, lat: 42.6144180297853 },
  { lng: 21.3958320617677, lat: 42.6140480041504 },
  { lng: 21.3926296234132, lat: 42.6143226623536 },
  { lng: 21.3889255523683, lat: 42.6153831481934 },
  { lng: 21.384084701538, lat: 42.6184921264648 },
  { lng: 21.3818550109864, lat: 42.6190719604492 },
  { lng: 21.3793582916261, lat: 42.6191024780274 },
  { lng: 21.3769187927247, lat: 42.6185684204102 },
  { lng: 21.3745098114015, lat: 42.6172485351564 },
  { lng: 21.3671989440919, lat: 42.6113586425782 },
  { lng: 21.3648395538331, lat: 42.6098785400391 },
  { lng: 21.3589839935303, lat: 42.6077995300294 },
  { lng: 21.3573036193849, lat: 42.6063308715821 },
  { lng: 21.3542327880859, lat: 42.5996971130371 },
  { lng: 21.3531932830811, lat: 42.5984268188476 },
  { lng: 21.3454208374023, lat: 42.591121673584 },
  { lng: 21.3438873291015, lat: 42.5893516540528 },
  { lng: 21.3412799835205, lat: 42.5853538513183 },
  { lng: 21.3397216796875, lat: 42.5835227966309 },
  { lng: 21.3326282501222, lat: 42.576831817627 },
  { lng: 21.3272953033448, lat: 42.5733299255372 },
  { lng: 21.321039199829, lat: 42.5700569152833 }
];
