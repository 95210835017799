import React from "react";

import { useTranslation } from "react-i18next";

import GoToCheckoutButton from "../../../components/Buttons/GoToCheckoutButton/GoToCheckoutButton";
import NavigationButton from "../../../components/Buttons/NavigationButton/NavigationButton";

import { MIN_ORDER_PRICE } from "../../../constants";

import "./CartTotals.scss";

const CartTotals = (props) => {
  const { t } = useTranslation();
  const totalValue = parseFloat(
      props.total.replace("&nbsp;€", "").replace(",", "")
    ),
    totalString = props.total.replace("&nbsp;", ""),
    canProcess = totalValue >= MIN_ORDER_PRICE,
    currentHour = new Date().getHours(),
    currentMinutes = new Date().getMinutes(),
    isWorkingHours =
      currentHour >= 9 &&
      (currentHour < 22 || (currentHour === 22 && currentMinutes <= 45));

  return (
    <React.Fragment>
      <h4 className="CartTotals__header">{t("title.summary")}</h4>
      <div className="CartTotals">
        <div className="CartTotals__item">
          <div className="CartTotals__label">{t("title.total")}:</div>
          <div className="CartTotals__value">{totalString}</div>
        </div>
      </div>
      {!canProcess && (
        <h5 className="CartTotals__message">
          {t("title.min_order_price", {
            item: MIN_ORDER_PRICE.toFixed(2)
          })}
        </h5>
      )}
      {!isWorkingHours && (
        <h5 className="CartTotals__message--error">
          Ju nuk mund të bëni porosi jashtë orarit të punës (09:00 - 23:00)
        </h5>
      )}
      <div className="CartTotals__buttons">
        <NavigationButton to="/" title={t("title.add_products")} />
        <GoToCheckoutButton canProcess={canProcess} />
      </div>
    </React.Fragment>
  );
};

export default CartTotals;
