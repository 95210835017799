import React, { useEffect } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { useSelector } from "react-redux";

import i18n from "./i18n";

import Address from "./containers/Address/Address";
import AppWrapper from "./containers/AppWrapper/AppWrapper";
import Archive from "./containers/Archive/Archive";
import Article from "./containers/Article/Article";
import Cart from "./containers/Cart/Cart";
import Checkout from "./containers/Checkout/Checkout";
import ContentPage from "./containers/ContentPage/ContentPage";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
import Orders from "./containers/Orders/Orders";
import OrderSingle from "./containers/Orders/OrderSingle/OrderSingle";
import PrivateRoute from "./containers/PrivateRoute/PrivateRoute";
import Profile from "./containers/Profile/Profile";
import PublicRoute from "./containers/PublicRoute/PublicRoute";
import Register from "./containers/Register/Register";
import SearchContainer from "./containers/SearchContainer/SearchContainer";
import SuccessPage from "./containers/SuccessPage/SuccessPage";
import { WrappedScrollToTop } from "./containers/WrappedScrollToTop/WrappedScrollToTop";
import Footer from "./layout/Footer/Footer";
import Header from "./layout/Header/Header";

import { MobileFixedButton } from "./components/MobileFixedButton/MobileFixedButton";

import "./styles/_base.scss";

function App() {
  const language = useSelector((state) => state.user.language);
  if (localStorage.getItem("SHOP_CHECKOUT")) {
    localStorage.removeItem("SHOP_CHECKOUT");
  }

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <AppWrapper>
      <BrowserRouter>
        <WrappedScrollToTop>
          <div className="App">
            <Header />
            <main className="main_content">
              <Switch>
                <Route path="/" exact component={Home} />

                <PublicRoute path="/kyqu" exact component={Login} />
                <PublicRoute path="/regjistrohu" exact component={Register} />
                <Route
                  path="/perditeso-fjalekalimin"
                  exact
                  component={ForgotPassword}
                />

                <Route path="/shporta" exact component={Cart} />
                <Route path="/proceso-porosine" exact component={Checkout} />

                <PrivateRoute path="/llogaria" exact component={Profile} />
                <PrivateRoute path="/adresa" exact component={Address} />
                <PrivateRoute path="/porosite" exact component={Orders} />
                <PrivateRoute
                  path="/porosite/:id"
                  exact
                  component={OrderSingle}
                />

                <Route path="/produktet/:category/" component={Archive} />
                <Route path="/produkti/:slug" component={Article} />
                <Route path="/kerko" exact component={SearchContainer} />
                <Route path="/page/success" exact component={SuccessPage} />
                <Route path="/porosia-sukses" exact component={SuccessPage} />
                <Route
                  path="/informacione/:slug"
                  exact
                  component={ContentPage}
                />
                <Route path="/:default" component={Home} />
              </Switch>
            </main>
            <MobileFixedButton />
            <Footer />
          </div>
        </WrappedScrollToTop>
      </BrowserRouter>
    </AppWrapper>
  );
}

export default App;
