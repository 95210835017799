import React, { useState } from "react";

import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { useMutation } from "react-apollo";

import gql from "graphql-tag";

import v4 from "uuid/v4";

import { emptyCart } from "../../actions/cartActions";

import { Alert, Col, Form, Row } from "reactstrap";

import {
  getSpecialRequestsString,
  removeSpecialRequests
} from "../../helpers/productSpecialRequest";

import BillingDetails from "./BillingDetails/BillingDetails";
import CardCheckout from "./CardCheckout/CardCheckout";
import Payment from "./Payment/Payment";
import ShippingDetails from "./ShippingDetails/ShippingDetails";
import TotalCheckout from "./TotalCheckout/TotalCheckout";

const CHECKOUT_MUTATION = gql`
  mutation checkoutMutation($inputData: CheckoutInput!) {
    checkout(input: $inputData) {
      clientMutationId
      order {
        id
        paymentMethod
        orderId
      }
      redirect
      result
    }
  }
`;
const UPDATE_ORDER_MUTATION = gql`
  mutation updateOrderMutation($inputData: UpdateOrderInput!) {
    updateOrder(input: $inputData) {
      clientMutationId
    }
  }
`;

const CheckoutFormGuest = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [coordinates, setCoordinates] = useState(null);
  const [addressChanged, setAddressChanged] = useState(false);
  const [checkoutData, setCheckoutData] = useState({
      billing: {
        firstName: "",
        lastName: "",
        company: "",
        city: "",
        address: "",
        country: "",
        email: "",
        telephone: "",
        guestCheckout: true,
        isLoaded: false
      },
      shipping: {
        firstName: "",
        lastName: "",
        company: "",
        city: "",
        address: "",
        differentShipping: false,
        isLoaded: false
      },
      comment: "",
      payment: {
        method: ""
      },
      isValid: false,
      isOrderUpdated: false,
      shippingAttributes: {
        text: "title.free",
        cost: "0"
      }
    }),
    [hasErrors, setHasErrors] = useState(false);

  const [
    checkout,
    { data: mutationData, error: mutationError, loading: mutationLoading }
  ] = useMutation(CHECKOUT_MUTATION);

  const [
    updateOrder,
    { error: mutationErrorOrder, loading: mutationLoadingOrder }
  ] = useMutation(UPDATE_ORDER_MUTATION);

  if (mutationLoading || mutationLoadingOrder) {
    return <p>Loading...</p>;
  }

  if (mutationErrorOrder || mutationError) {
    return (
      <div>
        <p>{t("description.website_issues_try_again")}</p>
        <div
          className="CartTotals__buttons"
          style={{ justifyContent: "flex-start" }}
        >
          <NavLink to="/" className="CartButtons__button">
            {t("title.go_back").toUpperCase()}
          </NavLink>
        </div>
      </div>
    );
  }

  const metaDetaItems = [];

  const handleUpdateOrderMetaValue = (metaArr) => {
    updateOrder({
      variables: {
        inputData: {
          clientMutationId: v4(),
          orderId: mutationData.checkout.order.orderId,
          metaData: metaArr,
          customerNote: checkoutData.comment
        }
      }
    });
  };

  if (mutationData && mutationData.checkout.result) {
    handleUpdateOrderMetaValue(metaDetaItems);
    setCheckoutData({
      ...checkoutData,
      isOrderUpdated: true
    });
    dispatch(emptyCart());
    removeSpecialRequests();
    props.history.replace("/porosia-sukses");
  }

  const handleBillingChange = (name, value, fromGeoApi = false) => {
    setCheckoutData({
      ...checkoutData,
      billing: {
        ...checkoutData.billing,
        [name]: value
      }
    });
    setAddressChanged(!fromGeoApi);
  };

  const handleShippingChange = (name, value) => {
    setCheckoutData({
      ...checkoutData,
      shipping: {
        ...checkoutData.shipping,
        [name]: value
      }
    });
  };

  const toggleShipping = () => {
    setCheckoutData({
      ...checkoutData,
      shipping: {
        ...checkoutData.shipping,
        differentShipping: !checkoutData.shipping.differentShipping
      }
    });
  };

  const handlePaymentChange = (value) => {
    setCheckoutData({
      ...checkoutData,
      payment: {
        ...checkoutData.payment,
        method: value
      }
    });
  };

  const handleCheckoutDataChange = (key, value) => {
    setCheckoutData({
      ...checkoutData,
      [key]: value
    });
  };

  // Init address by geolocation
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(initGeoLocation);
    }
  };

  function initGeoLocation(position) {
    var geocoder = new window.google.maps.Geocoder();
    geocodeLatLng(
      geocoder,
      position.coords.latitude,
      position.coords.longitude
    );
  }

  function geocodeLatLng(geocoder, lat, long) {
    var latlng = { lat: parseFloat(lat), lng: parseFloat(long) };
    setCoordinates(latlng);
    geocoder.geocode({ location: latlng }, function (results, status) {
      if (status === "OK") {
        if (results[0]) {
          handleBillingChange("address", results[0].formatted_address, true);
        }
      }
    });
  }

  const handleCheckoutSubmit = (e) => {
    e.preventDefault();
    const { billing, shipping, payment, comment } = checkoutData;

    if (!validFields()) {
      setHasErrors(true);
      window.scrollTo(0, 0);
      return;
    }

    // get special requests
    const productIds = props.cartData.cart.contents.nodes.map(
      (x) => x.product.productId
    );
    const specialRequests = getSpecialRequestsString(productIds);

    checkout({
      variables: {
        inputData: {
          clientMutationId: v4(),
          billing: {
            firstName: billing.firstName,
            lastName: billing.lastName,
            phone: billing.telephone,
            address1: billing.address,
            company: billing.company,
            country: "XK",
            email: billing.email,
            state: billing.state,
            city: billing.city,
            postcode: "10000"
          },
          metaData: [
            { key: "order_note", value: comment },
            { key: "order_specifics", value: specialRequests },
            {
              key: "lat_lng",
              value: coordinates ? `${coordinates.lat},${coordinates.lng}` : ""
            },
            { key: "address_changed", value: addressChanged }
          ],
          shipping: {
            firstName: shipping.firstName,
            lastName: shipping.lastName,
            address1: shipping.address,
            company: shipping.company,
            city: shipping.city,
            postcode: "10000"
          },
          paymentMethod: payment.method,
          shippingMethod: "free_shipping:1"
        }
      }
    });
  };

  const validField = (input) => {
    return input !== "";
  };

  const validFields = () => {
    const { billing, shipping } = checkoutData;

    const validBillingFields =
      validField(billing.firstName) &&
      validField(billing.lastName) &&
      validField(billing.address) &&
      validField(billing.email) &&
      validField(billing.telephone);

    const validShippingFields =
      validField(shipping.firstName) &&
      validField(shipping.lastName) &&
      validField(shipping.address) &&
      validField(shipping.telephone);

    if (shipping.differentShipping) {
      return validBillingFields && validShippingFields;
    }

    return validBillingFields;
  };

  return (
    <div className="GeneralForm">
      {hasErrors && (
        <Alert color="warning">{t("description.fill_all_fields_below")}</Alert>
      )}
      <Form
        onSubmit={(e) => {
          handleCheckoutSubmit(e);
        }}
      >
        <Row>
          <Col xs="12" sm="12" lg="6">
            <BillingDetails
              getLocation={getLocation}
              details={checkoutData.billing}
              onChange={(name, value) => handleBillingChange(name, value)}
            />
          </Col>
          <Col xs="12" sm="12" lg="6">
            <ShippingDetails
              details={checkoutData.shipping}
              comment={checkoutData.comment}
              toggleShipping={(toggle) => toggleShipping()}
              onChange={(name, value) => handleShippingChange(name, value)}
              onChangeComment={(name, value) => {
                console.log("comment name", name);
                console.log("comment value", value);

                handleCheckoutDataChange(name, value);
              }}
            />
            {/* {coordinates && (
              <div className="GoogleMaps">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCxxX74N5ItPCWtbsTu_YqmFNfgiTT_CPI"
                  }}
                  defaultCenter={coordinates}
                  defaultZoom={16}
                >
                  <Marker
                    lat={coordinates.lat}
                    lng={coordinates.lng}
                    name={t("title.here")}
                    color="red"
                  />
                </GoogleMapReact>
              </div>
            )} */}
          </Col>
          <Col xs="12" sm="12">
            <p>&nbsp;</p>
          </Col>
          <Col xs="12" sm="6">
            <Payment onChange={(value) => handlePaymentChange(value)} />
          </Col>
          <Col xs="12" sm="12">
            <p>&nbsp;</p>
          </Col>
          <Col xs="12" sm="12" lg="8" xl="8">
            <CardCheckout cartData={props.cartData} />
          </Col>
          <Col xs="12" sm="12" lg="4" xl="4">
            <TotalCheckout
              shippingAttributes={checkoutData.shippingAttributes}
              subTotal={props.cartData.cart.subtotal}
              total={props.cartData.cart.total}
              isValid={checkoutData.isValid}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default withRouter(CheckoutFormGuest);
