import React from "react";

import ContentLoader from "react-content-loader";

const ProductMobileLoader = () => (
  <ContentLoader
    height={2000}
    width={900}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="25" y="25" rx="0" ry="0" width="850" height="800" />
    <rect x="25" y="870" rx="0" ry="0" width="600" height="60" />
    <rect x="25" y="1000" rx="0" ry="0" width="300" height="40" />
    <rect x="25" y="1070" rx="0" ry="0" width="400" height="40" />
    <rect x="25" y="1200" rx="0" ry="0" width="850" height="100" />
    <rect x="25" y="1350" rx="0" ry="0" width="850" height="100" />
    <rect x="25" y="1500" rx="0" ry="0" width="400" height="50" />
    <rect x="25" y="1600" rx="0" ry="0" width="600" height="50" />
    <rect x="25" y="1700" rx="0" ry="0" width="800" height="50" />
    <rect x="25" y="1800" rx="0" ry="0" width="500" height="50" />
  </ContentLoader>
);

export default ProductMobileLoader;
