const jwt_decode = require("jwt-decode");

export const loadMoreTexts = {
  default: "description.show_more",
  loading: "title.loading",
  allLoaded: "description.all_products_loaded"
};

export const generalTexts = {
  error: "Si duket diqka ka shkuar gabim, ju lutemi provoni përseri"
};

export const addProductTexts = {
  default: "title.add_product",
  success: "title.product_added",
  update: "title.update_cart"
};

export function getPathSlug(path) {
  const slug = path.replace(/\s/g, "").split("/");

  if (slug[slug.length - 1] === "") {
    return slug[slug.length - 2];
  }

  return slug[slug.length - 1];
}

export function getSubNavigationRoute(string) {
  const slug = string.split("/product-category/");
  return slug;
}

export function localUser() {
  const localUser = localStorage.getItem("SHOP_USER");

  if (!localUser) {
    return false;
  }

  return JSON.parse(localUser);
}

export function isTokenExpired(token) {
  const decoded = jwt_decode(token);

  return decoded.exp * 1000 < Date.now();
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const m = ("0" + date.getMinutes()).substr(-2);
  const h = ("0" + date.getHours()).substr(-2);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return day + "/" + (monthIndex + 1) + "/" + year + " " + h + ":" + m;
}
