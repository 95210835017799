import React, { useState } from "react";

import { NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { removeFromCart } from "../../../../actions/cartActions";

import QuanitityInput from "../../../../components/QuantityInput/QuantityInput";

import { getProductSpecialRequest } from "../../../../helpers/productSpecialRequest";

import { LANGUAGES } from "../../../../constants";

import "./CartItem.scss";

import useCartMutations from "../../../../useCartMutation";

const CartItem = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.user);
  const { product, quantity, total } = props;
  const [cartQuantity, setCartQuantity] = useState(quantity); // eslint-disable-line no-unused-vars
  const { updateItemQuantities } = useCartMutations(product.id, null);
  // get special request
  const productSpecialRequest = getProductSpecialRequest(product.productId);

  return (
    <div className="CartItem d-flex">
      <div className="CartItem__thumb">
        <NavLink to={"/produkti/" + product.slug}>
          {product.image && (
            <img src={product.image.sourceUrl} alt="Cart Item" />
          )}
        </NavLink>
      </div>
      <div className="CartItem__content d-flex flex-column pl-3">
        <h3 className="CartItem__info CartItem__info--title">
          <NavLink to={"/produkti/" + product.slug}>
            <b>{t("title.product")}:</b>
            {language === LANGUAGES.en &&
            product.productAttributes?.englishProductName
              ? product.productAttributes.englishProductName
              : product.name}
          </NavLink>
        </h3>
        <h3 className="CartItem__info">
          <b>{t("title.price")}:</b> {product.price.replace("&nbsp;", "")}
        </h3>
        {productSpecialRequest.specialRequest !== "" && (
          <h3 className="CartItem__info">
            <b>{t("title.specifications")}:</b>{" "}
            {productSpecialRequest.specialRequest}
          </h3>
        )}
        <h4 className="CartItem__info mt-auto">
          <b>{t("title.quantity")}:</b>
          <QuanitityInput
            onChange={(value) => {
              updateItemQuantities(value);
            }}
            quantity={cartQuantity}
            smallBox
            inlineBox
          />
        </h4>
        <h4 className="CartItem__total">
          <b>{t("title.total_price")}:</b> {total.replace("&nbsp;", "")}
        </h4>
      </div>
      <div className="CartItem__actions">
        <span
          className="CartItem__remove"
          onClick={(e) => {
            updateItemQuantities(0);
            dispatch(removeFromCart(product.productId));
          }}
        ></span>
      </div>
    </div>
  );
};

export default CartItem;
